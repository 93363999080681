import React from "react";
import styled from 'styled-components';

const Container = styled.label`
  display: inline-flex;
`;

const Bar = styled.div`
  background-color: #3D6FC9;
  width: 4px;
  height: 1.25rem;
`;

const Text = styled.div`
  font-size: 0.875rem;
  color: #5C5A5A;  
  margin-left: 6px;
`

const BarWithLabel = ({className, text}) => {
    return <Container className={className}>
        <Bar />
        <Text>{text}</Text>
    </Container>
};

export default BarWithLabel;
