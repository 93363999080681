import React from "react";
import PropTypes from "prop-types";
import {useAllUndertakers} from "../queries/undertaker";
import UndertakerSelectBase from "./undertakerSelectBase";

const UndertakerSelect = ({disabled, value, onChange}) => {
    const {data, isLoading, isError} = useAllUndertakers();

    return <UndertakerSelectBase
        data={data}
        disabled={disabled}
        isLoading={isLoading}
        isError={isError}
        showLabel={false}
        showUnAssigned={true}
        value={value}
        onChange={onChange}
    />
}

UndertakerSelect.defaultProps = {
    disabled: false,
    value: "",
    onChange: ()=>{},
};

UndertakerSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default UndertakerSelect;
