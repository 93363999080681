import React from "react";
import utils from "../../../../utils";
import {route_path} from "../../CaseList_Route";
import CaseUndertakerSelect from "../../../../containers/CaseUndertakerSelect";
import useIsWeb from "../../../../hooks/useIsWeb";

const getUndertaker = () => {
    const filters = utils.get_filters();
    return utils.get_value_by_cur_filters({filters, field: "undertaker"});
}

const UndertakerSelect = () => {
    const isWeb = useIsWeb();
    const undertaker = getUndertaker();

    return <CaseUndertakerSelect
        isKoden={isWeb}
        value={undertaker}
        onChange={value=>{
            if(undertaker !== value){
                utils.changeParams({route_path, property: "undertaker", value, filters: utils.get_filters()});
            }
        }}
    />
};

export default UndertakerSelect;
