import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = () => ({});

const handleChange = (onChange=()=>{}) => {
    return (event)=>{
        onChange(event.target.value);
    };
};

function TextFieldWithError(props={}) {
    let { id="", label_test_id, input_test_id, error_test_id, type="text", width, label_width, errorText="", readOnly=false, label="", value="文字", onChange=()=>{} } = props;
    if(_.isNull(value)){
        value = "";
    }
    const is_error = !_.isEmpty(errorText);
    const error_text_id = `${id}-error`;

    return <FormControl error={is_error} aria-describedby={error_text_id} style={{width: "100%"}}>
        <InputLabel test_id={label_test_id} style={{width: label_width}} htmlFor={id}>{label}</InputLabel>
        <Input inputProps={{test_id: input_test_id}} style={{width}} readOnly={readOnly} type={type} id={id} value={value} onChange={handleChange(onChange)} />
        {is_error && <FormHelperText id={error_text_id} test_id={error_test_id}>{errorText}</FormHelperText>}
    </FormControl>;
}

TextFieldWithError.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    onChange: PropTypes.func,
    errorText: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    label_test_id: PropTypes.string,
    input_test_id: PropTypes.string,
    error_test_id: PropTypes.string
};

export default withStyles(styles)(TextFieldWithError);