import React from "react";
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import MyUserAvatar from "../../../Shared/MyUserAvatar";
import DateTimeField from "../../../Shared/DateTimeField";
import TagSelect from "../shared/TagSelect";
import RichTextField from "../../../RichTextField";
import TextButton from "../../../Shared/TextButton";
import isDisabled from "../shared/isDisabled";
import borderStyle from "../shared/borderStyle";

const Container = styled.div`
  height: 100%;
  padding: 1rem;
  
  ${props => props.isInline && css`
    ${borderStyle};
  `}
`;

const Box = styled.div`
  display: grid;
  grid-template-rows: 40px auto auto 1fr;
`;

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  
  > *:nth-child(2){
    margin-left: 1rem;
  }
`;

const TagContainer = styled.div`
  > *:first-child{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const TextContainer = styled.div`
    margin-top: 1rem;
    overflow-x: auto;
    
    [contenteditable]{
      min-height: fit-content;
      word-break: break-all;
    }
  
  ${props => !props.isInline && css`
    width: calc(100vw - 2rem);
  `}
`;

const CaseOneNoteEditor = ({className, disabled, isInline, note, onCancel, onChangeWrittenDate, onChangeTagIds, onChangeText, onSaved, submitText}) => {
    const {id, written_date, text, tag_ids} = note;

    return <Container className={className} isInline={isInline}>
        <Box>
            <TopBlock>
                <MyUserAvatar />
                <DateTimeField value={written_date} onChange={onChangeWrittenDate} />
            </TopBlock>
            <TagContainer>
                <TagSelect hasRemoved id={`CaseOneNotes-isInline-${isInline}-${id}`} label="新增標籤" values={tag_ids} onChange={onChangeTagIds} />
            </TagContainer>
            <TextContainer isInline={isInline}>
                <RichTextField id={`Aditor_${id}`} defaultValue={text} onChange={onChangeText} />
            </TextContainer>
            <div>
                <TextButton disabled={disabled} onClick={onCancel}>取消</TextButton>
                <TextButton disabled={isDisabled({disabled, text})} onClick={onSaved}>{submitText}</TextButton>
            </div>
        </Box>
    </Container>
};

CaseOneNoteEditor.defaultProps = {
    disabled: false,
    isInline: false,
    onCancel: ()=>{},
    onSaved: ()=>{},
};

CaseOneNoteEditor.propTypes = {
    disabled: PropTypes.bool,
    isInline: PropTypes.bool,
    onCancel: PropTypes.func,
    onSaved: PropTypes.func,
};

export default CaseOneNoteEditor;
