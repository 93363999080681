import _ from "lodash";
import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import _TagChips from "../TagChips";
import LabelButton from "./LabelButton";
import Popup from "./Popup";
import AddButtonWithLabel from "../../../../AddButtonWithLabel";

const TagChips = styled(_TagChips)`
  margin-top: 1rem; 
`;

const PcTagSelect = ({id:defaultId="", hasRemoved=false, isLabelButton=false, label="", values=[], onChange}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? defaultId : undefined;

    const close = () => {
        setAnchorEl(null);
    };

    return <>
        {isLabelButton ? <LabelButton onClick={handleClick}>{label}</LabelButton> : <AddButtonWithLabel label="新增標籤" onClick={handleClick} />}
        {!_.isEmpty(values) && <TagChips values={values} hasRemoved={hasRemoved} onRemove={id=>{
            const index = values.findIndex(_id => _id===id);

            onChange(update(values,{
                $splice: [[index, 1]]
            }));
        }} />}
        <Popper id={id} open={open} anchorEl={anchorEl} transition placement='bottom-start'>
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={close}>
                    <Fade {...TransitionProps} timeout={350}>
                        <div>
                            <Popup values={values} onConfirm={(values)=>{
                                onChange(values);
                                close();
                            }}/>
                        </div>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    </>
};

PcTagSelect.defaultProps = {
    label: "標籤",
    values: [],
    onChange: ()=>{}
};

PcTagSelect.propTypes = {
    label: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
};

export default PcTagSelect;
