import _ from "lodash";
import fileExtension from 'file-extension';
import queryString from "query-string";
import {push} from 'connected-react-router';
import base64 from 'base-64';
import momentTimeZone from "moment-timezone";
import appendQuery from "append-query";
import {ADMIN_EMAIL, DEFAULT_PAGE, IMG_PREFIX_URL, INDEX_URL} from '../config';
import roles from "../consts/roles";
import {store} from "../store";
import {ON_NOTIFICATION_CLOSE, ON_NOTIFICATION_OPEN} from "../actions/types";
import urgent_level_label_map from "../consts/urgent_level_label_map";

export default class utils {
    static redirect_href(href="/") {
        window.location.href = href;
    }

    static redirect_route_index() {
        this.redirect_route(INDEX_URL);
    }

    static redirect_href_index() {
        this.redirect_href(INDEX_URL);
    }

    static redirect_route(href=""){
        window._store.dispatch(push(href));
    }

    static refresh_page(){
        window.location.reload();
    }

    static getErrorObj(response){
        if(!_.isEmpty(response) && !_.isEmpty(response.data) && _.isNumber(response.data.errorCode)){
            const {errorCode, msg, info = {}} = response.data;
            return {errorCode, msg, info};
        }
        else{
            return {};
        }
    }

    static get_query(search=""){
        if(_.isEmpty(search)){
            search = window.location.search;
        }

        return queryString.parse(search);
    }

    static get_filters(search=""){
        if(_.isEmpty(search)){
            search = window.location.search;
        }

        const query = this.get_query(search);
        if(_.isEmpty(query) || _.isEmpty(query.filters)){
            return {};
        }
        else{
            try{
                const obj = JSON.parse(query.filters);
                return _.isEmpty(obj) ? {} : obj;
            }
            catch(e){
                return {};
            }
        }
    }

    static get_filters_for_list(){
        return {
            page: {"property":"page","value": 0,"type":"Number"},
            limit: {"property":"limit","value": 10000,"type":"Number"}
        };
    }

    static clear_pagination({filters={}}){
        delete filters.page;
        return filters;
    }

    static get_server_img_src(src=""){
        return `${IMG_PREFIX_URL}/${src}`;
    }

    static compareBy(a={}, b={}, columnName) {
        return a[columnName] - b[columnName];
    }

    static compareByPriority(a={}, b={}) {
        return utils.compareBy(a, b, "priority");
    }

    static is_admin_email(email=""){
        return email===ADMIN_EMAIL;
    }

    static get_page_by_cur_filters({filters={}}){
        return this.get_value_by_cur_filters({filters, field: "page", default_value: DEFAULT_PAGE});
    }

    static get_value_by_cur_filters({filters, field, default_value}){
        if(_.isEmpty(field) || _.isEmpty(filters) || !filters.hasOwnProperty(field)){
            return default_value;
        }
        else{
            const {value, type} = filters[field];
            if(type === "String" && _.isEmpty(value)){
                return default_value;
            }
            else if(type === "Number"){
                return parseInt(value);
            }
            else{
                return value;
            }
        }
    }

    static is_email_format(value=""){
        const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(value);
    }

    static encode_password(value=""){
        return base64.encode(value);
    }

    static toTaipeiDateStr(str){
        return momentTimeZone.tz(str, "Asia/Taipei").format("YYYY-MM-DD");
    }

    static toTaipeiTimeStr(str){
        return momentTimeZone.tz(str, "Asia/Taipei").format("YYYY-MM-DD HH:mm");
    }

    static toTaipeiTimeWithZoneIdStr(str){
        return momentTimeZone.tz(str, "Asia/Taipei").format();
    }

    static disabled_change_role(me){
        const {role} = me;
        return role!==roles.CEO && role!==roles.ADMIN;
    }

    static toISOString(str){
        if(_.isEmpty(str)){
            return null;
        }
        else{
            return new Date(str).toISOString();
        }
    }

    static clearParams({route_path}){
        this.changeParams({route_path});
    }

    static changeParams({route_path, filters={}, property="", type="String", value}){
        filters = utils.clear_pagination({filters});

        if(value == null){
            delete filters[property];
        }
        else if(type==="String" && _.isEmpty(value)){
            delete filters[property];
        }
        else if(type==="Number" && value === 0){
            delete filters[property];
        }
        else{
            filters[property] = {property, value, type};
        }

        if(_.isEmpty(filters)){
            utils.redirect_route(route_path);
        }
        else{
            utils.redirect_route(appendQuery(route_path, {filters: JSON.stringify(filters)}, {removeNull: true}));
        }
    }

    static openNotification(message=""){
        store.dispatch({
            type: ON_NOTIFICATION_OPEN,
            payload: { message }
        });

        //reset the state
        window.setTimeout(() => {
            store.dispatch({ type: ON_NOTIFICATION_CLOSE });
        }, 10000);
    }

    static getFileSizeInMB(file){
        return file.size / 1024 / 1024;
    }

    static changePage({route_path, page}){
        utils.changeParams({route_path, property: "page", type: "Number", value: page, filters: utils.get_filters()});
    }

    static isImgSrc(src="") {
        if(_.isEmpty(src)) return false;

        const image_extensions = ["jpeg", "jpg", "gif", "png"];
        const ext = fileExtension(src);
        return image_extensions.indexOf(ext)!==-1;
    }

    static getUrgentLevelLabel(urgent_level=""){
        return urgent_level_label_map[urgent_level] || "";
    }
}
