import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import SearchBar from "./SearchBar";
import useIsWeb from "../../hooks/useIsWeb";
import {APP_NAME, INDEX_URL, SPCA_NAME} from "../../config";
import {LogoImage} from "./Logo/Logo";

const Container = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  
  background-color: #39578E;
  color: #F7F7F7;
  display: flex;
  align-items: center;
  padding-right: 2rem;
`;

const Box = styled.div`
  display: flex;
  height: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
`;

const Logo = styled(LogoImage)`
  margin-bottom: 5px;
`;

const LinkBox = styled(Link)`
  display: flex;
  font-size: 1rem;
  color: #F7F7F7;
  text-decoration: none;
`

const Text = styled.h3`
  margin-left: .5rem;
`;

const DrawerIcon = styled(GiHamburgerMenu)`
  height: 100%;
  width: 5rem;
  user-select: none;
  cursor: pointer;
`;

const Header = ({onHamburgerClick})=>{
    const isWeb = useIsWeb();

    return <Container>
        <Box>
            <Left>
                <DrawerIcon onClick={onHamburgerClick}/>
                <LinkBox to={INDEX_URL} title={`${SPCA_NAME}-${APP_NAME}`}>
                    <Logo />
                    {isWeb && <Text>{APP_NAME}</Text>}
                </LinkBox>
            </Left>
            <SearchBar />
        </Box>
    </Container>
};

Header.propTypes = {

};

export default Header;
