import React from "react";
import Panel from "../shared/Panel";
import AnimalDetails from "./AnimalDetails";

const CaseOneAnimalInformation = ({caseOne}) => {
    const {animal_details=[]} = caseOne;

    return <Panel title="動物資訊">
        <AnimalDetails values={animal_details} />
    </Panel>
};

export default CaseOneAnimalInformation;
