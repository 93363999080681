import React, {memo} from "react";
import PropTypes from 'prop-types';
import TextFieldWithError from "../Shared/TextFieldWithError";
import validateUtil from '../../utils/validateUtil';
import {EMAIL_FORMAT} from '../../consts/msg';

const EmailInput = memo(({input_test_id, label_test_id, error_test_id, value, onChange=()=>{}}) => {
    const is_email = validateUtil.is_email_format(value);
    const errorText = !is_email? EMAIL_FORMAT : '';

    return <TextFieldWithError
        id="email"
        label="Email"
        input_test_id={input_test_id}
        label_test_id={label_test_id}
        error_test_id={error_test_id}
        value={value}
        onChange={onChange}
        errorText={errorText}
    />
});

export default EmailInput;

EmailInput.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    input_test_id: PropTypes.string,
    label_test_id: PropTypes.string,
    error_test_id: PropTypes.string
};
