import React from "react";
import PropTypes from "prop-types";
import Notification from '../containers/Notification';
import CenterLoading from "../components/CenterLoading/CenterLoading";
import Content from './Content';
import useScrollToTop from "../hooks/useScrollToTop";
import useCheckLogin from "../hooks/useCheckLogin";

function MainLayout({is_login_required, is_app_bar, component, background_color}){
    useScrollToTop();
    const {isLoading} = useCheckLogin(is_login_required);

    if (isLoading) {
        return <CenterLoading />
    }
    else {
        return <>
            <Notification/>
            <Content
                background_color={background_color}
                is_login_required={is_login_required}
                is_app_bar={is_app_bar}
                component={component}
            />
        </>
    }
}

MainLayout.defaultProps = {
    is_login_required: false,
    is_app_bar: false,
};

MainLayout.propTypes = {
    is_login_required: PropTypes.bool,
    is_app_bar: PropTypes.bool,
    component: PropTypes.elementType,
};

export default MainLayout;
