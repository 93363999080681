import React from "react";
import styled from 'styled-components';
import {FaTag} from "react-icons/fa";
import {AiFillCaretDown} from "react-icons/ai";

const Button = styled.button`
  width: 7rem;
  height: 2rem;
  border-radius: 4px;
  border: solid 1px #6C87C6;
  background-color: #FFFFFF;
  color: #6C87C6;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  outline: none;
  position: relative;
  padding-left: .5rem;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 3.25rem;
`;

const Text = styled.div`
  font-size: 0.875rem;
`;

const Arrow = styled(AiFillCaretDown)`
  position: absolute;
  right: .5rem;
`

const LabelButton = ({onClick=()=>{}}) => {
    return <Button onClick={onClick}>
        <Box>
            <FaTag style={{color: "#6C87C6"}} />
            <Text>標籤</Text>
        </Box>
        <Arrow />
    </Button>
};

export default LabelButton;