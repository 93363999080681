import React from "react";
import utils from "../../../../utils";
import {route_path} from "../../CaseList_Route";
import {useLocation} from "react-router";
import _ from "lodash";
import urgent_level_label_map from "../../../../consts/urgent_level_label_map";
import KodenSelect from "../../../../components/KodenSelect/KodenSelect";
import useIsWeb from "../../../../hooks/useIsWeb";
import DropdownList from "../../../../components/Shared/DropdownList";

const getUrgent = () => {
    const filters = utils.get_filters();
    return utils.get_value_by_cur_filters({filters, field: "urgent", default_value: "null"});
}

const UrgentSelect = () => {
    const isWeb = useIsWeb();
    useLocation();
    const urgent = getUrgent();

    const options = [{label: "緊急程度", value: "null"}].concat(_.keys(urgent_level_label_map).map((value)=>{
        const label = utils.getUrgentLevelLabel(value);
        return {value, label};
    }));

    const Component = isWeb ? KodenSelect : DropdownList;
    return <Component
        value={urgent}
        options={options}
        onChange={value=>{
            if(urgent!==value){
                utils.changeParams({route_path, property: "urgent", value, filters: utils.get_filters()});
            }
        }}
    />
};

export default UrgentSelect;
