import React from "react";
import styled from "styled-components";
import {useIsFetching, useIsMutating} from "react-query";
import LinearProgress from "@material-ui/core/LinearProgress";
import Portal from "@material-ui/core/Portal";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  opacity:${props=>props.isFetching ? 1: 0};
`;

const AppProgress = () => {
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    return <Portal>
        <Container isFetching={isFetching || isMutating}>
            <LinearProgress />
        </Container>
    </Portal>
};

export default AppProgress;