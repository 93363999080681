import React from "react";
import {useDeleteMutation} from "../../../queries/caseFile";
import FilesWithLabel from "../../Shared/FilesWithLabel";

const CaseOneFiles = (({values=[]})=>{
    const caseFileDeleteMutation = useDeleteMutation();

    return <FilesWithLabel
        isShowUpload={false}
        values={values}
        onDelete={id=> {
            if (window.confirm("確定刪除此筆資料?")) {
                caseFileDeleteMutation.mutate(id);
            }
        }}
    />
});

export default CaseOneFiles;

CaseOneFiles.propTypes = {

};
