import {useMutation, useQueryClient} from "react-query";
import ajax from "../actions/ajax";
import queryKeys from "./queryKeys";
import useCaseNum from "../components/CaseOne/shared/useCaseNum";

const prefix_url = "/case/personnel";

export const useUpdateRoleMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_PERSONNEL_UPDATE,({id, value}) => {
        return ajax.put(`${prefix_url}/${id}/role`, {value});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useUpdateNameMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_PERSONNEL_UPDATE,({id, value}) => {
        return ajax.put(`${prefix_url}/${id}/name`, {value});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useUpdateContactMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_PERSONNEL_UPDATE,({id, value}) => {
        return ajax.put(`${prefix_url}/${id}/contact`, {value});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useDeleteMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_PERSONNEL_DELETE,id => {
        return ajax.delete(`${prefix_url}/${id}`);
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useAddMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_PERSONNEL_ADD,() => {
        return ajax.post(prefix_url,{case_num});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};
