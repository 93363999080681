import React, {Fragment} from "react";
import styled from "styled-components";
import getTagOptions from "./getTagOptions";
import {useTagList} from "../../../../../queries/useTagList";
import _MaterialMultipleSelect from "../../../../Shared/MultipleSelect/MaterialMultipleSelect";
import TagChips from "../TagChips";

const MaterialMultipleSelect = styled(_MaterialMultipleSelect)`
  width: 100%;
`

const MobileTagSelect = ({id, label, values=[], onChange=()=>{}}) => {
    const {data, isLoading} = useTagList();
    if(isLoading) return <Fragment />

    return <MaterialMultipleSelect
        id={id}
        label={label}
        values={values}
        options={getTagOptions(data.data)}
        onChange={onChange}
        renderValue={tagIds => <TagChips values={tagIds} />}
    />
};

export default MobileTagSelect;