import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, TextField} from "@material-ui/core";
import useDebounceValue from "../../../hooks/useDebounceValue";

const getNumberValue = (value)=>{
    try{
        const number = parseInt(value, 10);
        return window.isNaN(number) ? 0: number;
    }
    catch(e){
        return 0;
    }
}

function WholeNumberField(props) {
    let {min=1, value: originalValue, onChange=()=>{}, readOnly=false} = props;
    const {value, setValue} = useDebounceValue(getNumberValue(originalValue), onChange);

    return <FormControl readOnly={readOnly} fullWidth={true}>
        <TextField
            {...props}
            inputProps={{ min }}
            type="number"
            value={value}
            onChange={ e=> setValue(getNumberValue(e.target.value)) }
        />
    </FormControl>
}

WholeNumberField.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    min: PropTypes.number
};

export default WholeNumberField;
