import {CancelToken} from "axios";
import ajax from "../actions/ajax";

const getPromise = (url, params = {}) => {
    const source = CancelToken.source()
    const promise = ajax.get(url, {
        // Pass the source token to your request
        cancelToken: source.token,
        params
    })

    // Cancel the request if React Query calls the `promise.cancel` method
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
    }

    return promise;
};

export default getPromise;