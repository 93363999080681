import useEnabledDeleteCount from "../../../hooks/useEnabledDeleteAccount";

const useHeaders = () => {
    const enabled_delete_case = useEnabledDeleteCount();

    let headers = [];

    if(enabled_delete_case){
        headers.push({
            id: "delete",
            label: "刪除",
            align: "center"
        });
    }

    return headers.concat([
        {
            id: "case_num",
            label: "案件編號",
            align: "center"
        },
        {
            id: "address",
            label: "案件地址",
            align: "left"
        },
        {
            id: "case_brief",
            label: "案件簡述",
            align: "left"
        },
        {
            id: "undertaker",
            label: "承辦人",
            align: "left"
        },
        {
            id: "urgentLevel",
            label: "緊急程度",
            align: "center"
        },
        {
            id: "status",
            label: "案件狀態",
            align: "left"
        },
        {
            id: "open",
            label: "對外開放",
            align: "center"
        },
    ]);
}

export default useHeaders;
