import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

const Container = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
`;

const EllipsisText = (({children}) => {
    return <Container>
        {children}
    </Container>
});

export default EllipsisText;

EllipsisText.propTypes = {
    children: PropTypes.any,
};
