export const SPCA_NAME = "SPCA";
export const APP_NAME = "調查部門線上系統";
export const INDEX_URL = "/";
export const API_URL = process.env.REACT_APP_API_URL;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;
export const DEFAULT_SELECT_VALUE = "";
export const DEFAULT_NUMBER_VALUE = -1;
export const IMG_PREFIX_URL = process.env.REACT_APP_IMG_PREFIX_URL;
export const DEFAULT_PAGE = 0;
export const ROWS_PER_PAGE = 20;
export const ADMIN_EMAIL = "admin@spca.org";
export const ANALYSIS_DEFAULT_YEAR = 2016;
