import React, {useState} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";
import validateUtil from '../../utils/validateUtil';
import Logo from "../Logo/Logo";
import {FLEX_CENTER_CENTER} from "../../consts/style";
import EmailInput from "../EmailInput/EmailInput";
import PasswordInput from "../PasswordInput/PasswordInput";
import LoginButton from "../LoginButton/LoginButton";
import {APP_NAME} from "../../config";
import {useLoginMutation} from "../../queries/auth";
import useKeypress from "../../hooks/useKeypress";

const PADDING_SPACE = 15;

const useStyles = createUseStyles({
    root: {
        width: 470,
        height: "100vh",
        backgroundColor :"#041C35",
        display: "flex",
        alignItems: "center"
    },
    wrapper:{
        width: "100%"
    },
    text_align_center:{
        textAlign: "center"
    },
    padding:{
        paddingLeft: PADDING_SPACE,
        paddingRight: PADDING_SPACE
    },
    margin_top:{
        marginTop: 10
    },
    font:{
        color: "white",
        fontSize: 18,
        fontFamily: `Microsoft JhengHei`,
        "& a":{
            color: "#194F92",
            textDecoration: "none"
        }
    },
    board_container:{
        ...FLEX_CENTER_CENTER,
        marginTop: 20,
        height: "auto"
    },
    board_container_inner:{
        backgroundColor: "white",
        borderTop: "solid 25px #BACFE8",
        borderLeft: "solid 25px #BACFE8",
        width: "100%",
        maxWidth: 470,
        "@media (max-width: 640px)": {
            maxWidth: 320
        }
    },
    flex_1:{
        flex: 1
    },
    LoginButton:{
        ...FLEX_CENTER_CENTER,
        height: 68
    }
});

const Login = (() => {
    const loginMutation = useLoginMutation();
    const [email, onEmailChange] = useState("");
    const [password, onPasswordChange] = useState("");

    const classes = useStyles();
    const is_email = validateUtil.is_email_format(email);
    const is_password = validateUtil.is_password(password);
    const is_disabled = !is_email || !is_password;

    const onLogin = ({email, password}) => {
        loginMutation.mutate({email, password});
    };
    useKeypress("Enter", ()=>onLogin({email, password}));

    return <div className={classes.root}>
        <div className={classes.wrapper}>
            <div test_id="login_logo" className={`${classes.text_align_center} ${classes.margin_top}`}>
                <Logo/>
            </div>
            <div test_id="login_app_name" className={`${classes.text_align_center} ${classes.font}`}>
                {APP_NAME}
            </div>
            <div className={classes.board_container}>
                <div className={`${classes.board_container_inner} ${classes.padding}`}>
                    <div className={`${classes.margin_top} ${classes.flex_1}`}>
                        <EmailInput
                            id="login_email"
                            input_test_id="login_email_input"
                            label_test_id="login_email_label"
                            error_test_id="login_email_error"
                            value={email}
                            onChange={onEmailChange}
                        />
                    </div>
                    <div className={`${classes.margin_top} ${classes.flex_1}`}>
                        <PasswordInput
                            id="login_password"
                            input_test_id="login_password_input"
                            label_test_id="login_password_label"
                            error_test_id="login_password_error"
                            value={password}
                            onChange={onPasswordChange}
                        />
                    </div>
                    <div
                        className={`${classes.LoginButton} ${classes.margin_top} ${classes.text_align_center} ${classes.flex_1}`}>
                        <LoginButton
                            test_id="login_submit"
                            is_logining={false}
                            is_disabled={is_disabled}
                            onClick={() => onLogin({email, password})}
                            onEnter={() => console.log({email, password})}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
});

Login.defaultProps = {
    onLogin: ()=>{}
};

Login.propTypes = {
    onLogin: PropTypes.func
};

export default Login;
