import React from "react";
import styled from "styled-components";
import {route_path} from "../../CaseList_Route";
import {Link} from "react-router-dom";

const Container = styled(Link)`
  color: #4672E8;
  font-family: Roboto,serif;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  
  text-decoration: none;
  
  &:hover{
    text-decoration: underline;
  }
`;

const ClearAll = () => {
    return <Container to={route_path}>
        清除
    </Container>
};

export default ClearAll;
