import React from "react";
import PropTypes from "prop-types";
import styled, {css} from 'styled-components';
import TextField from "../../../Shared/TextField";
import {
    useAddMutation,
    useDeleteMutation,
    useUpdateContactMutation,
    useUpdateNameMutation,
    useUpdateRoleMutation
} from "../../../../queries/casePersonnel";
import useIsWeb from "../../../../hooks/useIsWeb";
import MinusIcon from "../../../Shared/MinusIcon";
import AddButtonWithLabel from "../../../AddButtonWithLabel";

const Container = styled.div`
  margin-top: 0.75rem;
`;

const Row = styled.div`
  margin-top: 1rem;
  
  & > *{
    margin-top: 1rem;
  }
  
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-areas:
    "role    ."
    "name    ."
    "contact button";
  
  > *:nth-child(1) {
    grid-area: role;
  }
  > *:nth-child(2) {
    grid-area: name;
  }
  > *:nth-child(3) {
    grid-area: contact;
  }
  > *:nth-child(4) {
    grid-area: button;
  }
  
  ${props=>props.isWeb && css`
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas: "role name contact button";

      & > *:not(:last-child){
        padding-right: 1rem;  
      }      
      > *:nth-child(4) {
        justify-content: flex-start;
      }
  `}
`;

const CaseOnePersonnels = (({values=[]})=>{
    const isWeb = useIsWeb();
    const addMutation = useAddMutation();
    const deleteMutation = useDeleteMutation();
    const updateRoleMutation = useUpdateRoleMutation();
    const updateNameMutation = useUpdateNameMutation();
    const updateContactMutation = useUpdateContactMutation();

    return <Container>
        <AddButtonWithLabel label="新增聯絡人" onClick={()=> addMutation.mutate({})} />
        {values.map(({id, role, name, contact})=>{
            return <Row key={id} isWeb={isWeb}>
                <TextField value={role} label="角色" onChange={value=>{
                    updateRoleMutation.mutate({id, value: value});
                }} />
                <TextField value={name} label="姓名" onChange={value=>{
                    updateNameMutation.mutate({id, value: value});
                }} />
                <TextField value={contact} label="聯絡方式" onChange={value=>{
                    updateContactMutation.mutate({id, value: value});
                }} />
                <div style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
                    <MinusIcon onClick={()=>{
                        if (window.confirm("確定刪除此筆資料?")) {
                            deleteMutation.mutate(id);
                        }
                    }} />
                </div>
            </Row>
        })}
    </Container>
});

export default CaseOnePersonnels;

CaseOnePersonnels.propTypes = {
    values: PropTypes.array
};
