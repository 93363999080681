import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import _File from "../File";
import UploadButton from "../../CaseOne/CaseOneAdditionalFiles/UploadButton";
import utils from "../../../utils";
import useIsWeb from "../../../hooks/useIsWeb";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const File = styled(_File)`
  padding: 5px;
`;

const FilesWithLabel = ({className, isShowUpload=false, onFileTooBig=()=>{}, values=[], onDelete, onFileUploaded=()=>{}})=>{
    const isWeb = useIsWeb();
    return <Container className={className}>
        <div style={{display: "flex", flexDirection: isWeb? "row" : "column"}}>
            {isShowUpload && <UploadButton onFileTooBig={onFileTooBig} onFileUploaded={({src})=>onFileUploaded(src)} />}
            {values.map(({id, src, isImg=false})=><File key={id} id={id} src={src} isImg={utils.isImgSrc(src)} onDelete={onDelete} />)}
        </div>
    </Container>
};

export default FilesWithLabel;

FilesWithLabel.propTypes = {
    isShowUpload: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    values: PropTypes.array,
    onDelete: PropTypes.func,
    onFileUploaded: PropTypes.func,
    onFileTooBig: PropTypes.func,
};
