import React from 'react';
import PropTypes from 'prop-types';
import ReadOnlyTextField from "../ReadOnlyTextField";

function get_value_label(value = false) {
    return value ? "是" : "否";
}

const ReadOnlyBooleanTextField = (({className, value=false, onChange}) => {
    return <ReadOnlyTextField
        className={className}
        value={get_value_label(value)}
        onChange={onChange}
    />
});

ReadOnlyBooleanTextField.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ReadOnlyBooleanTextField;
