import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        marginLeft: 0
    },
    input: {
        display: 'none',
    },
});

function RaisedButton({ classes, disabled=false, children="送出", color="primary", onClick=()=>{} } ) {
    return <Button variant="contained" disabled={disabled} color={color} onClick={onClick} className={classes.button}>
        {children}
    </Button>
}

RaisedButton.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    onClick: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool
};

export default withStyles(styles)(RaisedButton);
