import {ON_NOTIFICATION_CLOSE, ON_NOTIFICATION_OPEN} from "./types";

export function onOpen({message=""}) {
    return {
        type: ON_NOTIFICATION_OPEN,
        payload: { open: true, message }
    };
}

export function onClose() {
    return {
        type: ON_NOTIFICATION_CLOSE,
        payload: { open: false }
    };
}