import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: -9px;
  right: -9px;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #C1C1C1;
  border-radius: 50%;
  cursor: pointer;
  
  &:before, &:after {
    position: absolute;
    left: 9px;
    top: 4px;
    content: ' ';
    width: 2px;
    height: 60%;
    background-color: #FFFFFF;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const CrossIcon = ({className, onClick}) => {
    return <Container className={className} onClick={onClick} />
};

export default CrossIcon;