import React, {useState} from "react";
import styled from "styled-components";
import LabelOptions from "./LabelOptions";
import FlexBox from "../../../../FlexBox";

const Container = styled.div`
  border:solid 1px #707070;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: 30rem;
  height: auto;
  margin-top: .5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CircleButton = styled(FlexBox)`
  border: solid 1px #707070;
  border-radius: 8px;
  width: 75px;
  height: 30px;
  
  color: #6C87C6;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  
  &:first-of-type{
    margin-right: 1rem;
  }
`;

const Popup = ({values: defaultValues=[], onConfirm=()=>{}}) => {
    const [valueSet, setValueSet] = useState(new Set(defaultValues));

    return <Container>
        <div>
            <LabelOptions valueSet={valueSet} onChange={({id, checked=false})=>{
                if(checked){
                    valueSet.add(id);
                }
                else{
                    valueSet.delete(id);
                }
                setValueSet(new Set(valueSet));
            }} />
        </div>
        <BottomContainer>
            <CircleButton onClick={()=>{
                onConfirm(Array.from(valueSet));
            }}>確認</CircleButton>
            <CircleButton onClick={()=>{
                setValueSet(new Set());
            }}>清除</CircleButton>
        </BottomContainer>
    </Container>
};

export default Popup;
