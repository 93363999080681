import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from "../TextField/TextField";

const styles = () => ({});

function PasswordField(props) {
    return <TextField type="password" autoComplete="current-password" {...props} />
}

PasswordField.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool
};

export default withStyles(styles)(PasswordField);