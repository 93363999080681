import _ from "lodash";
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import status_label_map from "../../consts/status_label_map";
import statusMap from "../../consts/status";
import DropdownList from "../../components/Shared/DropdownList";

function get_status_label(status){
    return status_label_map[status] || "";
}

const getOptions = ({enabledPending, enabledFinished})=>{
    return _.keys(status_label_map).map(value=>{
        switch(value){
            case statusMap.PENDING:{
                return {value, label: get_status_label(value), disabled: !enabledPending };
            }
            case statusMap.FINISHED:{
                return {value, label: get_status_label(value), disabled: !enabledFinished};
            }
            default:{
                return {value, label: get_status_label(value)};
            }
        }
    });
};

const StatusSelect = memo(({disabled, value, onChange, enabledPending, enabledFinished})=>{
    return <DropdownList
        disabled={disabled}
        options={getOptions({enabledPending, enabledFinished})}
        value={value}
        label="案件狀態"
        onChange={onChange}
    />
});

StatusSelect.defaultProps = {
    disabled: false,
    value: "",
    onChange: ()=>{},
    enabledPending: true,
    enabledFinished: true
};

StatusSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    enabledPending: PropTypes.bool,
    enabledFinished: PropTypes.bool,
};

export default StatusSelect;
