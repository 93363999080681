import React from "react";
import PropTypes from 'prop-types';
import UserTable from "../Shared/UserTable";
import RaisedButton from "../Shared/RaisedButton/RaisedButton";
import {redirect_route} from "../../routes/UserAdd_Route";
import {title} from "../../routes/UserList_Route";
import Title from '../Title';
import DefaultContainer from "../DefaultContainer";

const UserList = (()=>{
    return <DefaultContainer>
        <Title>{title}</Title>
        <RaisedButton color="primary" onClick={redirect_route}>新增帳號</RaisedButton>
        <UserTable />
    </DefaultContainer>
});

export default UserList;

UserList.propTypes = {
    records: PropTypes.object
};
