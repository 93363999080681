import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  
  &>*:first-child{
    margin-right: 8px;
  }
`;

function IconButtonWithText({ children, text, onClick=()=>{} } ) {
    return <Container onClick={onClick}>
        {children}
        {text}
    </Container>
}

IconButtonWithText.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default IconButtonWithText;
