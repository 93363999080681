import _ from "lodash";
import React from "react";
import utils from "../../../../utils";
import status from "../../../../consts/status";
import {route_path} from "../../CaseList_Route";
import useIsWeb from "../../../../hooks/useIsWeb";
import KodenSelect from "../../../../components/KodenSelect";
import DropdownList from "../../../../components/Shared/DropdownList";
import status_label_map from "../../../../consts/status_label_map";

function get_status_label(status){
    return status_label_map[status] || "";
}

function getStatus(){
    const filters = utils.get_filters();
    return utils.get_value_by_cur_filters({filters, field: "status", default_value: ""});
}

const getOptions = ()=>{
    return [{value: "", label: "案件狀態"}].concat(_.keys(status_label_map).map(value=>{
        switch(value){
            case status.FINISHED:{
                return {value, label: get_status_label(value)};
            }
            case status.PENDING:{
                return {value, label: get_status_label(value)};
            }
            default:{
                return {value, label: get_status_label(value)};
            }
        }
    }));
};

const CaseListQueryStatusSelect = () => {
    const isWeb = useIsWeb();
    const status = getStatus();
    const SelectComponent = isWeb ? KodenSelect : DropdownList;

    return <SelectComponent
        options={getOptions()}
        value={status}
        onChange={value=>{
            utils.changeParams({route_path, property: "status", value, filters: utils.get_filters()});
        }}
    />
};

export default CaseListQueryStatusSelect;
