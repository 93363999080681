import React from "react";
import styled, {css} from "styled-components";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FlexBox from "../FlexBox";
import Fade from "@material-ui/core/Fade";

const ArrowDown = styled.div`
  position: absolute;
  right: 14px;
  top: 11px;
  border: solid #818080;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(45deg);
`;

const Container = styled.div`
  width: 130px;
  height: 38px;
  font-size: 16px;
  color: #818080;
  
  font-weight: bold;
  border-radius: 25px;
  border: solid 1px #CECECE;
  background-color: #FFFFFF;
  user-select: none;
  cursor: pointer;
  
  display: grid;
  grid-template-columns: 9fr 1fr;
  
  position: relative;
`

const OptionContainer = styled.div`
    width: 130px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 10px;
    border: solid 1px #C1C1C1;
    background-color: white;
    margin-top: .5rem;
    cursor: pointer;
    user-select: none;
`;

const Option = styled.div`
  height: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #818080;
  background-color: white;
  
  ${props=>props.select && css`
    background-color: #F2F2F2;
  `};
  
  &:hover{
    background-color: #F2F2F2;
  }
  
  &:first-of-type{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  &:last-of-type{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const OptionText = styled.div`
  width: 80%;
  text-align: left;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const KodenSelect = ({options=[], value, onChange=()=>{}})=>{
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;

    const close = () => {
        setAnchorEl(null);
    };

    return <>
        <Container aria-describedby={id} onClick={handleClick}>
            <FlexBox>{options.find(e=>e.value===value)?.label}</FlexBox>
            <ArrowDown />
        </Container>
        <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={close}>
                    <Fade {...TransitionProps} timeout={350}>
                        <OptionContainer>
                            {options.map(({value: optionValue, label}) => {
                                return <Option key={optionValue} select={optionValue === value} onClick={()=>{
                                    onChange(optionValue);
                                    close();
                                }}>
                                    <OptionText title={label}>
                                        {label}
                                    </OptionText>
                                </Option>
                            })}
                        </OptionContainer>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    </>;
}

export default KodenSelect;
