import React, {useState} from "react";
import styled, {css} from "styled-components";
import Collapse from "@kunukn/react-collapse";
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

const Container = styled.div`
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: fit-content;
  border-radius: 8px;
  
  &:not(:last-child) {
      margin-bottom: 2.25rem;
  }
`;

const Title = styled.div`
  width: 100%;
  height: 45px;
  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
  ${props=>!props.open && css`
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `};
  background-color: ${props=>props.color};
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
  transition: background-color 300ms linear;
  
  user-select: none;
  cursor: pointer;
  
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
`;

const WhiteBoard = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1.5rem;
  background-color: white;
  font-size: 0.875rem;
  color: #5C5A5A;
  overflow: auto;
`;

const Arrow = styled(KeyboardArrowUp)`
  transition: all .3s;
  transform: rotate(${props=>props.open ? 0: -180}deg);
`;

const Panel = ({title, titleColor="#6C87C6", defaultOpen=true, children}) => {
    const [open, setOpen] = useState(defaultOpen);

    return <Container>
        <Title onClick={()=>setOpen(open=>!open)} color={titleColor} open={open}>
            {title}
            <Arrow open={open} />
        </Title>
        <Collapse style={{ transitionDuration: "270ms" }} isOpen={open}>
            <WhiteBoard>
                {children}
            </WhiteBoard>
        </Collapse>
    </Container>
};

export default Panel;
