import React, {Fragment} from "react";
import {useUpdateAnimalLocationRoad} from "../../../../queries/case";
import {useAddressAllFetch} from "../../../../queries/address";
import {DEFAULT_SELECT_VALUE} from "../../../../config";
import DropdownList from "../../../Shared/DropdownList";

const RoadList = (({animal_location_city, animal_location_area, animal_location_road})=>{
    const updateAnimalLocationRoad = useUpdateAnimalLocationRoad();
    const {isLoading: addressLoading, data: addressData} = useAddressAllFetch(animal_location_city);

    if(addressLoading) return <Fragment />

    const roads = addressData?.data[animal_location_area];
    if(!roads) return <Fragment />

    const options = [{label: "選擇街道", value: DEFAULT_SELECT_VALUE}];
    roads.forEach(value => {
        options.push({ value, label: value});
    });
    const disabled = updateAnimalLocationRoad.isLoading;

    return <DropdownList
        disabled={disabled}
        options={options}
        value={animal_location_road}
        onChange={updateAnimalLocationRoad.mutate}
    />
});

export default RoadList;

RoadList.propTypes = {
};
