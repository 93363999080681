import React, {Fragment} from "react";
import MultipleSelect from "../../../Shared/MultipleSelect";
import {useComplaintCategoryList} from "../../../../queries/complaintCategory";

const ComplaintCategorySelect = ({id="", disabled=false, values=[], onChange}) => {
    const {isLoading, data} = useComplaintCategoryList();
    if(isLoading){
        return <Fragment />
    }

    const options = data.data.map(({id, name}) => {
        return {value: id, label: name};
    });

    return <MultipleSelect
        id={id}
        options={options}
        disabled={disabled}
        values={values}
        onChange={onChange}
    />
};

export default ComplaintCategorySelect;
