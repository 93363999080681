import React from "react";
import styled from 'styled-components';
import VideoButton from "../../VideoButton";
import CrossIcon from "./CrossIcon";
import useIsWeb from "../../../hooks/useIsWeb";

const A = styled.a`
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: relative;
`

const FileImage = ({link, isImg, onDelete}) => {
    const isWeb = useIsWeb();

    return <A href={link} rel="noopener noreferrer" target="_blank">
        {isImg ? <img width={75} height={75} src={link} alt={""} /> : <VideoButton />}
        {isWeb && <CrossIcon onClick={event=>{
            event.preventDefault();
            onDelete();
        }} />}
    </A>
};

export default FileImage;
