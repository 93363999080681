import React from "react";
import {KodenInlineEditMultilineField} from "@scott/koden.koden-inline-edit-multiline-field";

const TextArea = ({id, defaultValue, onChange, ...props}) => {
    const inProps = {
        id,
        maxRows: 5,
        defaultValue,
        error: "",
        onConfirm: onChange
    };

    if(props.maxLength){
        inProps.maxLength = props.maxLength;
    }

    return <KodenInlineEditMultilineField
        {...inProps}
    />
};

export default TextArea;
