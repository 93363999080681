import {v4 as uuidv4} from 'uuid';
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useDropzone} from 'react-dropzone';
import axios from "axios";
import _DottedBox from "./DottedBox";
import {UPLOAD_URL} from "../../../../config";
import utils from "../../../../utils/utils";

const imageExtensions = ["ase", "art", "bmp", "blp", "cd5", "cit", "cpt", "cr2", "cut", "dds", "dib", "djvu", "egt", "exif", "gif", "gpl", "grf", "icns", "ico", "iff", "jng", "jpeg", "jpg", "jfif", "jp2", "jps", "lbm", "max", "miff", "mng", "msp", "nitf", "ota", "pbm", "pc1", "pc2", "pc3", "pcf", "pcx", "pdn", "pgm", "PI1", "PI2", "PI3", "pict", "pct", "pnm", "pns", "ppm", "psb", "psd", "pdd", "psp", "px", "pxm", "pxr", "qfx", "raw", "rle", "sct", "sgi", "rgb", "int", "bw", "tga", "tiff", "tif", "vtf", "xbm", "xcf", "xpm", "3dv", "amf", "ai", "awg", "cgm", "cdr", "cmx", "dxf", "e2d", "egt", "eps", "fs", "gbr", "odg", "svg", "stl", "vrml", "x3d", "sxd", "v2d", "vnd", "wmf", "emf", "art", "xar", "png", "webp", "jxr", "hdp", "wdp", "cur", "ecw", "iff", "lbm", "liff", "nrrd", "pam", "pcx", "pgf", "sgi", "rgb", "rgba", "bw", "int", "inta", "sid", "ras", "sun", "tga"];
const videoExtensions = ["3g2", "3gp", "aaf", "asf", "avchd", "avi", "drc", "flv", "m2v", "m4p", "m4v", "mkv", "mng", "mov", "mp2", "mp4", "mpe", "mpeg", "mpg", "mpv", "mxf", "nsv", "ogg", "ogv", "qt", "rm", "rmvb", "roq", "svi", "vob", "webm", "wmv", "yuv"];

const getExtension = (filename) => {
    const ext = (/[^./\\]*$/.exec(filename) || [""])[0];
    return ext.toLowerCase();
}

export const isImageFileName = (fileName) => {
    return imageExtensions.indexOf(getExtension(fileName))!==-1;
};

export const isVideoFileName = (fileName) => {
    return videoExtensions.indexOf(getExtension(fileName))!==-1;
};

const UploadText = styled.div`
  color: #434242;
  font-size: 2.5rem;
`;

const DottedBox = styled(_DottedBox)`
  margin: 5px;
`

const UploadButton = (({onFileTooBig, onFileUploadReady, onFileUploading, onFileUploaded, onFileUploadFailed}) => {
    const accept = imageExtensions.map(e => `.${e}`).concat(videoExtensions.map(e => `.${e}`));

    const onDrop = (files => {
        const fileWithIds = files.map(file=>{
            return {file, id: uuidv4()};
        });

        fileWithIds.forEach(({id, file}) => {
            //if this file size is over 30 MB
            if(utils.getFileSizeInMB(file) > 30){
                onFileTooBig({id, file});
            }
            else{
                onFileUploadReady({id});

                const body = new FormData();
                body.append('file', file);
                axios.post(UPLOAD_URL, body, {
                    onUploadProgress: event => {
                        const percentCompleted = Math.round((event.loaded * 100) / event.total);
                        onFileUploading({id, percentCompleted});
                    }
                })
                    .then(({data: {src}}) => {
                        onFileUploaded({id, src});
                    })
                    .catch(err =>  onFileUploadFailed({id, err}));
            }
        });
    });
    const {getRootProps, getInputProps} = useDropzone({onDrop, accept, multiple: true});

    return <DottedBox {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadText>+</UploadText>
    </DottedBox>
});

export default UploadButton;

UploadButton.defaultProps = {
    onFileTooBig: ()=>{},
    onFileUploadReady: ()=>{},
    onFileUploading: ()=>{},
    onFileUploaded: ()=>{},
    onFileUploadFailed: ()=>{}
};

UploadButton.propTypes = {
    onFileTooBig: PropTypes.func,
    onFileUploadReady: PropTypes.func,
    onFileUploading: PropTypes.func,
    onFileUploaded: PropTypes.func,
    onFileUploadFailed: PropTypes.func,
};
