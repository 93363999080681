import React from "react";
import styled from "styled-components";
import MyUserAvatar from "../components/Shared/MyUserAvatar";
import {Divider} from "@material-ui/core";
import IconAndText from "./IconAndText";
import useList from "./useList";
import useShowAccount from "../hooks/useShowAccount";

const Container = styled.div`
  padding-top: 1rem;
  
  border-right: solid .5px #C0C0C0;
  background-color: #FFFFFF;
  width: ${props=>props.open ? "10rem": "5rem"};
  height: 100%; 
  
  display: grid;
  grid-template-rows: repeat(${props=>props.showAccount? 8: 7}, 3rem) 1px 3rem;
  
  position: fixed;
  top: var(--header-height);
  left: 0;
`;

const OneBox = styled.div`
  display: flex;
  justify-content: ${props=>props.open ? "left": "center"};
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Sidebar = ({open}) => {
    const showAccount = useShowAccount();
    const list = useList();

    return <Container open={open} showAccount={showAccount}>
        <OneBox open={open}>
            <MyUserAvatar />
        </OneBox>
        {list.map(({id, isLine=false, icon, txt, onClick})=>{
            if(isLine){
                return <Divider key={id} />
            }
            return <IconAndText key={id} onClick={onClick} open={open} icon={icon} txt={txt} />
        })}
    </Container>
};

export default Sidebar;
