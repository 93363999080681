import React, {Fragment} from 'react';
import {route_path} from "../../CaseList_Route";
import utils from "../../../../utils";
import {getYear} from "../YearSelect/YearSelect";
import {DEFAULT_NUMBER_VALUE} from "../../../../config";
import KodenSelect from "../../../../components/KodenSelect/KodenSelect";
import DropdownList from "../../../../components/Shared/DropdownList/DropdownList";
import useIsWeb from "../../../../hooks/useIsWeb";

const getMonth = () => {
    const filters = utils.get_filters();
    return utils.get_value_by_cur_filters({filters, field: "month", default_value: DEFAULT_NUMBER_VALUE});
}

const MonthSelect = (()=>{
    const isWeb = useIsWeb();
    const year = getYear()
    const month = getMonth();
    const isHide = year === DEFAULT_NUMBER_VALUE;
    const options = [
        {label: "月份", value: DEFAULT_NUMBER_VALUE},
        {label: "1月", value: 1},
        {label: "2月", value: 2},
        {label: "3月", value: 3},
        {label: "4月", value: 4},
        {label: "5月", value: 5},
        {label: "6月", value: 6},
        {label: "7月", value: 7},
        {label: "8月", value: 8},
        {label: "9月", value: 9},
        {label: "10月", value: 10},
        {label: "11月", value: 11},
        {label: "12月", value: 12}
    ];

    if(isHide){
        return <Fragment />
    }

    const Component = isWeb ? KodenSelect : DropdownList;
    return <Component options={options} value={month} onChange={value=>{
        if(value !== month){
            utils.changeParams({route_path, property: "month", type: "Number", value, filters: utils.get_filters()});
        }
    }} />
});

MonthSelect.propTypes = {
};

export default MonthSelect;
