import React from "react";
import utils from "../utils/utils";
import CaseOne from "../components/CaseOne";

const prefix_route_path = "/case";
export const route_path = `${prefix_route_path}/:case_num`;

export function redirect_route(case_num = "") {
    utils.redirect_route(get_route_path(case_num));
}

export function get_route_path(case_num = "") {
    return `${prefix_route_path}/${case_num}`;
}

const CaseOne_Route = ()=>{
    return <CaseOne />
}

export default CaseOne_Route;

CaseOne_Route.propTypes = {
};
