import {RECEIVE_ERROR_MSG} from "../actions/types";

const INITIAL_STATE = {
    login: {},
    case_one: {},
    add_user_one: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RECEIVE_ERROR_MSG: {
            const {payload} = action;
            return {
                ...state,
                ...payload
            };
        }
        default: {
            return state;
        }
    }
}