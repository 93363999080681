import React from 'react';
import PropTypes from "prop-types";
import TextField from "../TextField";

const ReadOnlyTextField = ({className, label, value, onChange, fullWidth}) => {
    return <TextField
        className={className}
        label={label}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        readOnly={true}
    />
};

ReadOnlyTextField.defaultProps = {
    onChange: ()=>{},
    fullWidth: false
};

ReadOnlyTextField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
};

export default ReadOnlyTextField;
