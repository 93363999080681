import React, {Fragment} from "react";
import styled from "styled-components";
import {useTagList} from "../../../../../queries/useTagList";
import LabelOption from "./LabelOption";
import getTagOptions from "./getTagOptions";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`


const LabelOptions = ({valueSet, onChange=()=>{}}) => {
    const {data, isLoading} = useTagList();
    if(isLoading) return <Fragment />

    const options = getTagOptions(data.data);
    return <Container>
        {options.map(({value, label}) => {
            const checked = valueSet.has(value);
            return <LabelOption key={value} value={value} checked={checked} label={label} onChange={({target: {name:id, checked}})=>{
                onChange({id, checked});
            }}/>
        })}
    </Container>;
};

export default LabelOptions;