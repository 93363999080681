import React, {Fragment} from "react";
import {
    useUpdateAnimalLocationArea,
    useUpdateAnimalLocationDetails,
    useUpdateAnimalLocationRoad
} from "../../../../queries/case";
import {useAddressAllFetch} from "../../../../queries/address";
import DropdownList from "../../../Shared/DropdownList";
import {DEFAULT_SELECT_VALUE} from "../../../../config";

const AreaList = (({animal_location_city, animal_location_area})=>{
    const updateAnimalLocationArea = useUpdateAnimalLocationArea();
    const updateAnimalLocationRoad = useUpdateAnimalLocationRoad();
    const updateAnimalLocationDetails = useUpdateAnimalLocationDetails();

    const {isLoading: addressLoading, data: addressData} = useAddressAllFetch(animal_location_city);

    if(addressLoading) return <Fragment />

    const areas = addressData.data;
    const options = [{label: "選擇區域", value: DEFAULT_SELECT_VALUE}];
    Object.keys(areas).forEach((value) => {
        options.push({ value, label: value});
    });
    const disabled = updateAnimalLocationArea.isLoading || updateAnimalLocationRoad.isLoading || updateAnimalLocationDetails.isLoading;

    return <DropdownList
        options={options}
        disabled={disabled}
        value={animal_location_area}
        onChange={value=>{
            updateAnimalLocationArea.mutate(value);
            updateAnimalLocationRoad.mutate(DEFAULT_SELECT_VALUE);
            updateAnimalLocationDetails.mutate("");
        }}
    />
});

export default AreaList;

AreaList.propTypes = {
};
