import React, {Fragment} from "react";
import styled, {css} from "styled-components";
import _DropdownList from "../../../Shared/DropdownList";
import {useCaseTypes} from "../../../../queries/caseType";
import useIsWeb from "../../../../hooks/useIsWeb";

const DropdownList = styled(_DropdownList)`
  ${props => props.isWeb && css`
    width: 14rem;
  `};
`

const CaseTypeList = ({disabled=false, value, onChange}) => {
    const isWeb = useIsWeb();
    const {isLoading, data} = useCaseTypes();

    if(isLoading){
        return <Fragment />;
    }

    return <DropdownList
        disabled={disabled}
        isWeb={isWeb}
        options={[{value: "null", label: "未選擇"}].concat(data.data.map(({id: value, name: label})=>{
            return {label, value};
        }))}
        value={value}
        onChange={onChange}
    />
};

export default CaseTypeList;
