import {ON_FETCH_ME} from "../actions/types";

const DEFAULT_STATE = {

};

const INITIAL_STATE = DEFAULT_STATE;

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ON_FETCH_ME:{
            const me = action.payload;
            return {...me};
        }
        default:{
            return state;
        }
    }
}
