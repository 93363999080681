import React, {memo} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import utils from "../../../utils/utils";
import TrashCanButton from "../TrashCanButton";
import FileImage from "./FileImage";
import useIsMobile from "../../../hooks/useIsMobile";

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr;
`;

const DeleteButton = styled(TrashCanButton)`
  display: flex;
  align-items: flex-end;
  margin-left: .5rem;
`;

const File = memo(({className, id, onDelete, isImg=false, src=""})=>{
    const isMobile = useIsMobile();
    const link = utils.get_server_img_src(src);

    return <Container className={className}>
        <FileImage isImg={isImg} link={link} onDelete={()=>onDelete(id)} />
        {isMobile && <DeleteButton onClick={()=>onDelete(id)} />}
    </Container>
});

export default File;

File.propTypes = {
    onDelete: PropTypes.func,
    src: PropTypes.string,
    isImg: PropTypes.bool
};
