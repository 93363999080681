import _ from "lodash";
import React, {memo} from "react";
import styled, {css} from "styled-components";
import CaseOneEmpty from "../CaseOneEmpty";
import CenterLoading from "../CenterLoading";
import {useCaseOne} from "../../queries/case";
import CaseOneInformation from "./CaseOneInformation";
import CaseOneBasicInformation from "./CaseOneBasicInformation";
import CaseOneRelatedPeople from "./CaseOneRelatedPeople";
import useIsWeb from "../../hooks/useIsWeb";
import CaseOneAnimalInformation from "./CaseOneAnimalInformation";
import CaseOneNotes from "./CaseOneNotes";
import CaseOnePdfDownload from "./CaseOnePdfDownload";
import DefaultContainer from "../DefaultContainer";

const Container = styled(DefaultContainer)`
  position: relative;
  
  ${props => props.isWeb && css`
    display: grid;
    grid-template-columns: 18.75rem 1fr;
    grid-template-areas: 
        "basic info"
        "basic people"
        "basic animal"
        "basic notes"
        "pdf   pdf"
    ;
      
    > *:first-child {
      grid-area: basic;
      margin-right: 1rem;
    }
      
    > *:nth-child(2) {
      grid-area: info;
    }
      
    > *:nth-child(3) {
      grid-area: people;
    }
      
    > *:nth-child(4) {
      grid-area: animal;
    }
      
    > *:nth-child(5) {
      grid-area: notes;
    }
      
    > *:nth-child(6) {
      grid-area: pdf;
    }
  `};
`;

const CaseOne = (() => {
    const {isLoading, isError, error, data} = useCaseOne();
    const isWeb = useIsWeb();

    if (isLoading) return <CenterLoading />
    else if (isError) {
        return `${error?.response?.data?.msg}`
    }

    const caseOne = data.data;
    if(_.isEmpty(caseOne)){
        return <CaseOneEmpty />
    }
    else{
        return <Container isWeb={isWeb}>
            <CaseOneBasicInformation caseOne={caseOne} />
            <CaseOneInformation caseOne={caseOne} />
            <CaseOneRelatedPeople caseOne={caseOne} />
            <CaseOneAnimalInformation caseOne={caseOne} />
            <CaseOneNotes caseOne={caseOne} />
            <CaseOnePdfDownload />
        </Container>
    }
});

CaseOne.propTypes = {
};

export default memo(CaseOne);
