import _ from 'lodash';
import React, {Fragment} from "react";
import {useUpdateAnimalLocationDetails} from "../../../../queries/case";
import TextField from "../../../Shared/TextField/TextField";

const LocationDetails = (({animal_location_road, animal_location_details})=>{
    const updateAnimalLocationDetails = useUpdateAnimalLocationDetails();

    if(_.isEmpty(animal_location_road)) return <Fragment />

    return <TextField value={animal_location_details} onChange={updateAnimalLocationDetails.mutate} />
});

export default LocationDetails;

LocationDetails.propTypes = {
};
