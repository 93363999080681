import React, {memo} from "react";
import PropTypes from "prop-types";
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    root:{
        backgroundColor: "#194F92",
        width: 72,
        height: 28,
        border: 0,
        color: "white",
        appearance: "none",
        borderRadius: 5,
    }
});

function get_label({is_logining=false}){
    return is_logining? "登入中..." : "登入";
}

const LoginButton = memo(({test_id, is_logining=false, is_disabled=false, onClick=()=>{}}) => {
    const classes = useStyles();
    const label = get_label({is_logining});

    return <input
        test_id={test_id}
        type="button"
        className={classes.root}
        onClick={onClick}
        disabled={is_disabled}
        value={label}
    />
});

export default LoginButton;

LoginButton.propTypes = {
    is_disabled: PropTypes.bool,
    is_logining: PropTypes.bool,
    onClick: PropTypes.func,
    test_id: PropTypes.string
};
