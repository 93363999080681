import React from "react";
import styled, {css} from 'styled-components';
import Panel from "../shared/Panel";
import BarWithLabel from "../shared/BarWithLabel";
import CaseTypeList from "./CaseTypeList";
import {useUpdateTypeId} from "../../../queries/case";
import CityList from "./CityList";
import AreaList from "./AreaList";
import RoadList from "./RoadList";
import LocationDetails from "./LocationDetails";
import ReadOnlyBooleanTextField from "../../Shared/ReadOnlyBooleanTextField";
import ReadOnlyTextAreaField from "../../Shared/ReadOnlyTextAreaField";
import CaseOneFiles from "../CaseOneFiles";
import CaseOneAdditionalFiles from "../CaseOneAdditionalFiles";
import AddressLink from "./AddressLink";
import MarginContainer from "../../MarginContainer";
import useIsWeb from "../../../hooks/useIsWeb";

const MarginTopBarLabel = styled(BarWithLabel)`
  margin-top: 1.25rem;
`

const AddressContainer = styled(MarginContainer)`
  ${props => !props.isWeb ? css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "city    area"
        "road    ."
        "details ."
        "link    link";
      
      > *:nth-child(1) {
        grid-area: city;
        padding-right: 1rem;
      }
      
      > *:nth-child(2) {
        grid-area: area;
        padding-left: 1rem;
      }
      
      > *:nth-child(3) {
        padding-top: 1rem;
        grid-area: road;
      }
      
      > *:nth-child(4) {
        padding-top: 1rem;
        grid-area: details;
      }
      
      > *:nth-child(5) {
        padding-top: 1rem;
        grid-area: link;
      }
  ` : css`
    display: flex;
    flex-wrap: wrap;
    
    & > *:nth-child(-n+3) {
      width: 8.125rem;
      margin-right: 3rem;
    }
    
    & > *:nth-child(4) {
      width: auto;
    }
    
    & > *:last-child {
      flex: 1 1 100%;
    }
  `};
`;

const BooleanContainer = styled.div`
  ${props=>props.isWeb && css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `};
`;

const CloudLinkField = styled(ReadOnlyTextAreaField)`
  ${props => props.isWeb && css`
    width: 14rem;
  `};
`;

const CaseOneInformation = ({caseOne}) => {
    const isWeb = useIsWeb();
    const updateTypeId = useUpdateTypeId();
    const {type_id, is_reported, is_public, details, cloud_link, animal_location_city, animal_location_area, animal_location_road, animal_location_details, files, file_additionals} = caseOne;

    return <Panel title="案件資訊">
        <BarWithLabel text="案件類型" />
        <MarginContainer>
            <CaseTypeList disabled={updateTypeId.isLoading} value={type_id} onChange={updateTypeId.mutate} />
        </MarginContainer>
        <MarginTopBarLabel text="地址" />
        <AddressContainer isWeb={isWeb}>
            <CityList animal_location_city={animal_location_city} />
            <AreaList animal_location_city={animal_location_city} animal_location_area={animal_location_area} />
            <RoadList animal_location_city={animal_location_city} animal_location_area={animal_location_area} animal_location_road={animal_location_road} />
            <LocationDetails animal_location_road={animal_location_road} animal_location_details={animal_location_details} />
            <AddressLink
                animal_location_city={animal_location_city}
                animal_location_area={animal_location_area}
                animal_location_road={animal_location_road}
                animal_location_details={animal_location_details}
            />
        </AddressContainer>
        <BooleanContainer isWeb={isWeb}>
            <div>
                <MarginTopBarLabel text="是否已通報主管機關/其它動保團體" />
                <MarginContainer>
                    <ReadOnlyBooleanTextField value={is_reported} />
                </MarginContainer>
            </div>
            <div>
                <MarginTopBarLabel text="是否同意提供聯絡資訊給主管機關" />
                <MarginContainer>
                    <ReadOnlyBooleanTextField value={is_public} />
                </MarginContainer>
            </div>
        </BooleanContainer>
        <MarginTopBarLabel text="案件情況詳述" />
        <MarginContainer>
            <ReadOnlyTextAreaField fullWidth isWeb={isWeb} value={details} />
        </MarginContainer>
        <MarginTopBarLabel text="雲端連結" />
        <MarginContainer>
            <CloudLinkField isWeb={isWeb} value={cloud_link} />
        </MarginContainer>
        <MarginTopBarLabel text="相關照片/影片" />
        <MarginContainer>
            <CaseOneFiles values={files} />
        </MarginContainer>
        <MarginTopBarLabel text="補充照片/影片" />
        <MarginContainer>
            <CaseOneAdditionalFiles values={file_additionals} />
        </MarginContainer>
    </Panel>
};

export default CaseOneInformation;
