import React from "react";
import styled, {css} from "styled-components";
import Panel from "../shared/Panel";
import ReadOnlyTextField from "../../Shared/ReadOnlyTextField";
import {get_label} from "../../../consts/gender";
import getWhistleblowerSourceLabel from "./getWhistleblowerSourceLabel";
import CaseOnePersonnels from "./CaseOnePersonnels";
import MarginContainer from "../../MarginContainer";
import TextField from "../../Shared/TextField";
import {useUpdateWhistleblowerEmail, useUpdateWhistleblowerPhone} from "../../../queries/case";
import useIsWeb from "../../../hooks/useIsWeb";
import LabelAndField from "./LabelAndField";
import BarWithLabel from "../shared/BarWithLabel";

const Group = styled.div`
  ${props => props.isWeb ? css`
    display: flex;
    flex-wrap: wrap;
    
    & > *{
      margin: 1rem 1rem 1rem 0;
    }
  ` : css`
        & > *{
          margin-bottom: 1rem;
        }
  `};
`;

const CaseOneRelatedPeople = ({caseOne}) => {
    const isWeb = useIsWeb();
    const {line_id, whistleblower_name, whistleblower_email, whistleblower_phone , whistleblower_gender, whistleblower_age, whistleblower_source, personnels} = caseOne;
    const updateWhistleblowerPhone = useUpdateWhistleblowerPhone();
    const updateWhistleblowerEmail = useUpdateWhistleblowerEmail();

    return <Panel title="相關人員資訊">
        {isWeb && <BarWithLabel text="通報人資訊" />}
        <Group isWeb={isWeb}>
            <LabelAndField label="姓名" value={whistleblower_name} component={ReadOnlyTextField} />
            <LabelAndField label="性別" value={get_label(whistleblower_gender)} component={ReadOnlyTextField} />
            <LabelAndField label="年齡" value={whistleblower_age} component={ReadOnlyTextField} />
            <LabelAndField label="得知通報管道" value={getWhistleblowerSourceLabel(whistleblower_source)} component={ReadOnlyTextField} />
            <LabelAndField label="電話" value={whistleblower_phone} component={TextField} onChange={value=>{
                updateWhistleblowerPhone.mutate(value);
            }} />
            <LabelAndField label="Email" value={whistleblower_email} component={TextField} onChange={value=>{
                updateWhistleblowerEmail.mutate(value);
            }} />
            <LabelAndField label="LINE ID" value={line_id} component={ReadOnlyTextField} />
        </Group>
        <BarWithLabel text="相關人員資訊" />
        <MarginContainer>
            <CaseOnePersonnels values={personnels} />
        </MarginContainer>
    </Panel>
};

export default CaseOneRelatedPeople;
