import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import borderStyle from "../shared/borderStyle";

const Container = styled.div`
  ${borderStyle};
  border: solid .5px #6C87C6;
  border-radius: 6px;
  
  display: flex;
  align-items: center;
  padding-left: 24px;
  
  color: #C1C1C1;
  height: 2rem;
  font-size: 0.75rem;
`;

const CaseOneNoteAddBoard = ({className, onClick}) => {
    return <Container className={className} onClick={onClick}>新增紀錄...</Container>
};

CaseOneNoteAddBoard.defaultProps = {
    onClick: ()=>{}
};

CaseOneNoteAddBoard.propTypes = {
    onClick: PropTypes.func
};

export default CaseOneNoteAddBoard;
