import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
`;

const SelectElement = styled.select`
  width: 100%;
  height: 40px;
  padding-left: 5px;
  padding-right: 25px;
  border: solid .5px #AFAEAE;
  background-color: #FFFFFF;
  font-size: 1rem;
  appearance: none;
  text-overflow: ellipsis;
  
  &:focus{
    outline: none;
  }
`;

const NativeMultipleSelect = (({className, id, disabled=false, values, onChange=()=>{}, options=[]}) => {
    return <Container className={className}>
        <SelectElement disabled={disabled} value={values} id={id} multiple onChange={event=>{
            onChange(Array.from(event.target.selectedOptions, option => option.value));
        }}>
            {options.map(({value, label})=><option key={value} value={value}>{label}</option>)}
        </SelectElement>
    </Container>
});

export default NativeMultipleSelect;

NativeMultipleSelect.propTypes = {

};
