import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';

const Title = memo(({children})=>{
    return <Typography test_id="title" gutterBottom variant="h6">
        {children}
    </Typography>
});

export default Title;

Title.propTypes = {
    children: PropTypes.any
};
