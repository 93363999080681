import React, {Fragment} from 'react';
import utils from "../../../../utils";
import {route_path} from "../../CaseList_Route";
import {useCaseYear} from "../../../../queries/case";
import {DEFAULT_NUMBER_VALUE} from "../../../../config";
import KodenSelect from "../../../../components/KodenSelect";
import useIsWeb from "../../../../hooks/useIsWeb";
import DropdownList from "../../../../components/Shared/DropdownList";

export function getYear(){
    const filters = utils.get_filters();
    return utils.get_value_by_cur_filters({filters, field: "year", default_value: DEFAULT_NUMBER_VALUE});
}

const YearSelect = (()=>{
    const isWeb = useIsWeb();
    const {isLoading, isError, data} = useCaseYear();
    const year = getYear();

    if(isLoading || isError) return <Fragment />

    const years = data.data;

    const options = [{label: "年份", value: DEFAULT_NUMBER_VALUE}].concat(years.map(value => {
        return {label: value.toString(), value}
    }));

    const Component = isWeb ? KodenSelect : DropdownList;
    return <Component options={options} value={year} onChange={value=>{
        if(value === DEFAULT_NUMBER_VALUE){
            utils.changeParams({route_path, property: "month", type: "Number", value: DEFAULT_NUMBER_VALUE, filters: utils.get_filters()});
        }
        if(value!==year){
            utils.changeParams({route_path, property: "year", type: "Number", value, filters: utils.get_filters()});
        }
    }} />
});

YearSelect.propTypes = {
};

export default YearSelect;
