const useHeaders = () => {
    return [
        {
            id: "avatar",
            align: "center",
            label: "",
        },
        {
            id: "name",
            align: "left",
            label: "名稱",
        },
        {
            id: "email",
            align: "left",
            label: "Email",
        },
        {
            id: "role",
            align: "left",
            label: "角色",
        },
        {
            id: "is_active",
            align: "left",
            label: "是否啟用",
        },
        {
            id: "change_pw",
            align: "center",
            label: "",
        },
        {
            id: "change_color",
            align: "center",
            label: "",
        },
        {
            id: "delete",
            align: "center",
            label: "",
        },
    ];
};

export default useHeaders;
