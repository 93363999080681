import React from "react";
import styled, {css} from 'styled-components';
import AnimalSpeciesSelect from "../../AnimalSpeciesSelect";
import ComplaintCategorySelect from "../../ComplaintCategorySelect";
import WholeNumberField from "../../../../Shared/WholeNumberField";
import {
    useDeleteMutation,
    useUpdateAnimalSpecies,
    useUpdateComplaintCategory,
    useUpdateCountMutation,
    useUpdateCurrentBrief
} from "../../../../../queries/caseAnimalDetail";
import CurrentBriefSelect from "../../CurrentBriefSelect";
import MinusIcon from "../../../../Shared/MinusIcon";
import FlexBox from "../../../../FlexBox";
import useIsWeb from "../../../../../hooks/useIsWeb";

const Container = styled.div`
  height: 15rem;
  display: grid;
  
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "animal  animal  animal  count"
    "problem problem problem problem"
    "case    case    case    button"
  ;
  
  > *:nth-child(1) {
    grid-area: animal;
    padding-right: 3rem;
  }
  
  > *:nth-child(2) {
    grid-area: count;
  }
  
  > *:nth-child(3) {
    grid-area: problem;
  }
  
  > *:nth-child(4) {
    grid-area: case;
  }
  
  > *:nth-child(5) {
    grid-area: button;
  }
  
  .MuiInput-formControl{
    margin-top: 0;
  }
  
  ${props => props.isWeb && css`
    height: auto;
    grid-template-columns: 7.5rem 3.25rem 10rem 10rem auto;
    grid-template-rows: 3rem;
    grid-template-areas: "animal count problem case button";
    
    > *:nth-child(1) {
        padding-right: 1rem;
    }
    
    > *:nth-child(2) {
        padding-right: 1rem;
    }
    
    > *:nth-child(3) {
        padding-right: 1rem;
    }
    
    > *:nth-child(5) {
      display: flex;
      justify-content: left;
      padding-left: 1rem;
    }
    
    &:not(:last-child){
        margin-bottom: 1rem;
      }
  `}
`;

const Text = styled.div`
  color: #707070;
`;

const DeleteBox = styled.div`
  ${FlexBox}{
    height: 100%;
  }
`

const AnimalDetail = ({value:animal_detail={}}) => {
    const updateAnimalSpecies = useUpdateAnimalSpecies();
    const updateCountMutation = useUpdateCountMutation();
    const updateComplaintCategory = useUpdateComplaintCategory();
    const updateCurrentBrief = useUpdateCurrentBrief();
    const deleteMutation = useDeleteMutation();
    const isWeb = useIsWeb();

    const {id, animal_species, count, complaint_category, current_brief} = animal_detail;

    return <Container isWeb={isWeb}>
        <div>
            <Text>動物種類</Text>
            <AnimalSpeciesSelect disabled={updateAnimalSpecies.isLoading} value={animal_species} onChange={value=>{
                updateAnimalSpecies.mutate({id, value});
            }} />
        </div>
        <div>
            <Text>數量</Text>
            <WholeNumberField value={count} onChange={value=>{
                updateCountMutation.mutate({id, value});
            }}  />
        </div>
        <div>
            <Text>案件問題</Text>
            <ComplaintCategorySelect id={`ComplaintCategorySelect_${id}`} disabled={updateComplaintCategory.isLoading} values={complaint_category} onChange={values=>{
                updateComplaintCategory.mutate({id, values});
            }} />
        </div>
        <div>
            <Text>處理情況</Text>
            <CurrentBriefSelect id={`CurrentBriefSelect_${id}`} disabled={updateCurrentBrief.isLoading} values={current_brief} onChange={values=>{
                updateCurrentBrief.mutate({id, values});
            }} />
        </div>
        <DeleteBox>
            <FlexBox>
                <MinusIcon onClick={()=>{
                    if (window.confirm("確定刪除此筆資料?")) {
                        deleteMutation.mutate(id);
                    }
                }} />
            </FlexBox>
        </DeleteBox>
    </Container>
};

export default AnimalDetail;
