import {useEffect} from 'react';
import {useLocation} from "react-router";

const useScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, [location.search]);
    return null;
};

export default useScrollToTop;
