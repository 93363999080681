import useMe from "../../../hooks/useMe";
import UserAvatar from "../UserAvatar";
import React from "react";

const MyUserAvatar = () => {
    const {display_name, color} = useMe();

    return <UserAvatar color={color} value={display_name} />
};

export default MyUserAvatar;
