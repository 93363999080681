import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import reduxThunk from "redux-thunk";
import {connectRouter, routerMiddleware} from "connected-react-router";
import reducers from "./reducers";
import history from "./history";

export let store;

const createRootReducer = (history) => combineReducers({
    ...reducers,
    router: connectRouter(history)
});

export function configureStore(preloadedState){
    store = createStore(
        createRootReducer(history),
        preloadedState,
        compose(
            applyMiddleware(
                reduxThunk,
                routerMiddleware(history)
            ),
        ),
    );
    window._store = store;

    return store;
}
