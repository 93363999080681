import React from 'react';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';
import NativeMultipleSelect from "./NativeMultipleSelect";
import MaterialMultipleSelect from "./MaterialMultipleSelect";

const MultipleSelect = (({className, id, disabled=false, options, label, values, onChange}) => {
    if(isMobile){
        return <NativeMultipleSelect
            className={className}
            id={id}
            values={values}
            disabled={disabled}
            onChange={onChange}
            options={options}
        />
    }
    else{
        return <MaterialMultipleSelect
            className={className}
            id={id}
            label={label}
            values={values}
            disabled={disabled}
            onChange={onChange}
            options={options}
        />
    }
});

MultipleSelect.defaultProps = {
    disabled: false,
    options: [],
    values: [],
    onChange: ()=>{}
};

MultipleSelect.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })),
    disabled: PropTypes.bool,
    values: PropTypes.array,
    onChange: PropTypes.func
};

export default MultipleSelect;
