import React, {memo} from 'react';
import {AiFillVideoCamera} from "react-icons/ai";
import PropTypes from "prop-types";

const VideoButton = memo(({onClick=()=>{}})=>{
    return <AiFillVideoCamera
        onClick={onClick}
        style={{ color:"#194F92", width: 75, height: 75 }}
    />
});

export default VideoButton;

VideoButton.propTypes = {
    onClick: PropTypes.string
};
