import React from "react";
import PropTypes from "prop-types";
import {useAddMutation, useDeleteMutation} from "../../../queries/caseFileAdditional";
import FilesWithLabel from "../../Shared/FilesWithLabel";

const CaseOneAdditionalFiles = (({values=[]})=>{
    const addMutation = useAddMutation();
    const deleteMutation = useDeleteMutation();

    return <FilesWithLabel
        isShowUpload
        values={values}
        onFileUploaded={addMutation.mutate}
        onFileTooBig={({file})=>{
            window.alert(`${file.name}超過30MB`);
        }}
        onDelete={id=>{
            if(window.confirm("確定刪除此筆資料?")){
                deleteMutation.mutate(id);
            }
        }}
    />
});

export default CaseOneAdditionalFiles;

CaseOneAdditionalFiles.propTypes = {
    values: PropTypes.array,
    onUploaded: PropTypes.func,
    onFileDelete: PropTypes.func
};
