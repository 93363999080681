import {useMutation, useQuery} from "react-query";
import utils from "../utils";
import queryKeys from "./queryKeys";
import getPromise from "./getPromise";
import ajax from "../actions/ajax";

const prefix_url = "/user";

export const useUserList = ()=>{
    const filters = utils.get_filters();
    return useQuery([queryKeys.USER_ALL, filters], () => getPromise(prefix_url, {filters}), {
        refetchOnMount: true
    });
}

export const useUserRoleChange = () => {
    return useMutation(async ({record, toRole})=>{
        await ajax.put(`${prefix_url}/${record.id}/role/${toRole}`);
    });
};

export const useUserDelete = () => {
    return useMutation(record => ajax.delete(`/user/${record.id}`));
};

export const useUserActiveChange = () => {
    return useMutation(async ({record, is_active})=>{
        await ajax.put(`${prefix_url}/${record.id}/is_active/${is_active}`);
    });
};

export const useUserPasswordChange = () => {
    return useMutation(async ({id, pw_1, pw_2})=>{
        await ajax.post(`${prefix_url}/${id}/password`, {pw_1: utils.encode_password(pw_1), pw_2: utils.encode_password(pw_2)});
    });
};

export const useUserAdd = () => {
    return useMutation(async ({display_name, role, email, password})=>{
        await ajax.post(prefix_url, {display_name, role, email, password: utils.encode_password(password)});
    });
};

export const useUserColorChange = () => {
    return useMutation(async ({id, color})=>{
        await ajax.put(`${prefix_url}/${id}/color/${encodeURIComponent(color)}`);
    });
};
