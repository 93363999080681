import React from "react";
import utils from "../utils/utils";
import UserAdd from "../components/UserAdd/UserAdd";

export const route_path = "/users_add";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

const UserAdd_Route = ()=>{
    return <UserAdd/>;
}

export default UserAdd_Route;

UserAdd_Route.propTypes = {
};
