import _ from "lodash";
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import DropdownList from "../Shared/DropdownList/DropdownList";
import roles_map from "../../consts/roles_map";

export const DEFAULT_VALUE = "null";

export function get_role_label(value){
    return roles_map[value];
}

const get_options = ()=>{
    return _.keys(roles_map).map(value=>{
        return {value, label: get_role_label(value)};
    });
};

const RoleSelect = memo(({disabled=false, value=DEFAULT_VALUE, onChange=()=>{}})=>{
    return <DropdownList disabled={disabled} options={get_options()} value={value} onChange={onChange} />
});

RoleSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default RoleSelect;
