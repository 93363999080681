import {route_path as user_route_path, title as user_list_title} from "../routes/UserList_Route";
import account from "./icons/account.svg";
import pet from "./icons/pet.svg";
import map from "./icons/map.svg";
import type from "./icons/type.svg";
import notepad from "./icons/notepad.svg";
import tag from "./icons/tag.svg";
import analysis from "./icons/analysis.svg";
import logoutSvg from "./icons/logout.svg";
import {
    route_path as animal_speciesList_route_path,
    title as animal_speciesList_title
} from "../routes/AnimalSpeciesList_Route";
import {route_path as case_typeList_route_path, title as case_typeList_title} from "../routes/CaseTypeList_Route";
import {
    route_path as complaint_categoryList_route_path,
    title as complaintCategoryList_title
} from "../routes/ComplaintCategoryList_Route";
import {
    route_path as current_brief_route_path,
    title as current_briefList_title
} from "../routes/CurrentBriefList_Route";
import {route_path as tag_route_path, title as tagList_title} from "../routes/TagList_Route";
import {route_path as analysis_route_path, title as analysis_title} from "../routes/Analysis_Route";
import utils from "../utils";
import {logout} from "../actions/logout";
import useShowAccount from "../hooks/useShowAccount";

const useList = () => {
    const showAccount = useShowAccount();

    const arr = [];
    if(showAccount){
        arr.push({id: "user_list_title", href: user_route_path, txt: user_list_title, icon: account, onClick(){
            utils.redirect_route(user_route_path);
        } });
    }
    return arr.concat([
        {id: "animal_speciesList_title", txt: animal_speciesList_title, icon: pet, onClick(){
            utils.redirect_route(animal_speciesList_route_path);
        }},
        {id: "case_typeList_title", txt: case_typeList_title, icon: map, onClick(){
            utils.redirect_route(case_typeList_route_path);
        }},
        {id: "complaintCategoryList_title", txt: complaintCategoryList_title, icon: type, onClick() {
            utils.redirect_route(complaint_categoryList_route_path);
        }},
        {id: "current_briefList_title", txt: current_briefList_title, icon: notepad, onClick() {
            utils.redirect_route(current_brief_route_path);
        }},
        {id: "tag_title", txt: tagList_title, icon: tag, onClick() {
            utils.redirect_route(tag_route_path);
        }},
        {id: "analysis_title", txt: analysis_title, icon: analysis, onClick() {
            utils.redirect_route(analysis_route_path);
        }},
        {id: "is_line", isLine: true},
        {id: "logout", txt: "登出", icon: logoutSvg, onClick: logout},
    ]);
}

export default useList;
