import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import utils from "../utils/utils";
import Title from "../components/Title/Title";
import WholeNumberField from "../components/Shared/WholeNumberField/WholeNumberField";
import {ANALYSIS_DEFAULT_YEAR, API_URL} from "../config";
import {onChange} from "../actions/analysis";
import {get_token} from "../actions/token";
import DownloadButton from "../components/DownloadButton/DownloadButton";
import DefaultContainer from "../components/DefaultContainer";

export const title = "分析資料";

export const route_path = "/analysis";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

export function generate_export_link({year}){
    return `${API_URL}/analysis/export?year=${year}&token=${get_token()}`
}

class Analysis_Route extends PureComponent {
    render(){
        const {year=ANALYSIS_DEFAULT_YEAR, onChange=()=>{}} = this.props;
        const link = generate_export_link({year});

        return <DefaultContainer>
            <Title>{title}</Title>
            <WholeNumberField
                label="年"
                min={ANALYSIS_DEFAULT_YEAR}
                value={year}
                onChange={year=>onChange({year})}
            />
            <DownloadButton onClick={()=> utils.redirect_href(link) } />
        </DefaultContainer>
    }
}

function mapStateToProps({analysis}) {
    return {...analysis};
}

export default connect(mapStateToProps, {onChange})(Analysis_Route);

Analysis_Route.propTypes = {
    year: PropTypes.number
};
