import React, {Fragment, memo} from 'react';
import SaveIcon from '@material-ui/icons/Save';
import RaisedButton from "../Shared/RaisedButton/RaisedButton";

const DownloadButton = memo((props={})=>{
    return (
        <RaisedButton {...props}>
            <Fragment>
                <SaveIcon />
                下載
            </Fragment>
        </RaisedButton>
    );
});

export default DownloadButton;

DownloadButton.propTypes = {};
