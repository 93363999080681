import React from 'react';
import PropTypes from "prop-types";
import TextField from "../TextField";

const ReadOnlyTextAreaField = ({className, fullWidth=false, value, onChange}) => {
    return <TextField
        fullWidth={fullWidth}
        className={className}
        value={value}
        onChange={onChange}
        readOnly
        multiline
    />
};

ReadOnlyTextAreaField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func
};

export default ReadOnlyTextAreaField;
