import {useMutation, useQuery, useQueryClient} from "react-query";
import ajax from "../actions/ajax";
import queryKeys from "./queryKeys";
import useCaseNum from "../components/CaseOne/shared/useCaseNum";
import getPromise from "./getPromise";

const prefix_url = "/case/note";

export const useNote = ({id}, onSuccess=()=>{}) => {
    return useQuery([queryKeys.CASE_NOTE_ONE, id], () => getPromise(`${prefix_url}/${id}`), {
        refetchOnMount: true,
        onSuccess
    });
};

export const useUpdate = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_NOTE_UPDATE,note => {
        return ajax.put(prefix_url, {case_num, note});
    }, {
        async onSuccess() {
            await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
        }
    });
};

export const useDeleteMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_NOTE_DELETE,id => {
        return ajax.delete(`${prefix_url}/${id}`);
    }, {
        async onSuccess() {
            await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
        }
    });
};

export const useAddMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_NOTE_ADD,note => {
        return ajax.post(prefix_url,{case_num, note});
    }, {
        async onSuccess() {
            await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
        }
    });
};
