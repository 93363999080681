import React, {memo} from "react";

const Logo = memo(() => {
    return <img src="/img/logo.png" alt="spca"/>
});

export default Logo;

Logo.propTypes = {
};
