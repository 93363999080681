import React from "react";
import styled from 'styled-components';
import AnimalDetail from "./AnimalDetail";
import {useAddMutation} from "../../../../queries/caseAnimalDetail";
import AddButtonWithLabel from "../../../AddButtonWithLabel";

const Container = styled.div`
  > *:nth-child(1) {
    margin-bottom: 1rem;
  }
`;

const AnimalDetails = ({values=[]}) => {
    const addMutation = useAddMutation();

    return <Container>
        <AddButtonWithLabel label="新增動物資訊" onClick={()=> addMutation.mutate({})} />
        {values.map((animal_detail) => <AnimalDetail key={animal_detail.id} value={animal_detail} />)}
    </Container>
};

export default AnimalDetails;
