import _ from "lodash";
import styled from "styled-components";
import React, {useState} from "react";
import Panel from "../shared/Panel";
import _CaseOneNoteAddBoard from "./CaseOneNoteAddBoard";
import CaseOneNoteAdd from "./CaseOneNoteAdd";
import _CaseOneNoteView from "./CaseOneNoteView";
import FullScreenDialog from "../../FullScreenDialog";
import TagSelect from "./shared/TagSelect";
import CaseOneNoteEdit from "./CaseOneNoteEdit";
import useIsWeb from "../../../hooks/useIsWeb";
import useIsMobile from "../../../hooks/useIsMobile";

const CaseOneNoteAddBoard = styled(_CaseOneNoteAddBoard)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const CaseOneNoteView = styled(_CaseOneNoteView)`
  &:not(:last-of-type){
    margin-bottom: 1rem;
  }
`

const CaseOneNotes = ({caseOne}) => {
    const isMobile = useIsMobile();
    const isWeb = useIsWeb();
    const {notes=[]} = caseOne;
    const [queryTagIds, setQueryTagIds] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editNoteId, setEditNoteId] = useState("");

    const filterByTagIds = ({tag_ids=[]}) => {
        if(queryTagIds.length===0) return true;

        return _.intersection(queryTagIds, tag_ids).length!==0;
    };

    const isOpenWebAdd = isWeb && isAdding;

    return <Panel title="案件記錄">
        {isMobile && <>
            <FullScreenDialog open={isAdding} onClose={()=>setIsAdding(false)}>
                <CaseOneNoteAdd
                    onCancel={()=> setIsAdding(false) }
                    onSaved={() => setIsAdding(false) }
                />
            </FullScreenDialog>
            <FullScreenDialog open={isEditing} onClose={()=>setIsEditing(false)}>
                <CaseOneNoteEdit
                    id={editNoteId}
                    onCancel={()=> setIsEditing(false) }
                    onSaved={() => setIsEditing(false) }
                />
            </FullScreenDialog>
        </>}
        <TagSelect id="CaseOneNotes-TagSelect" isLabelButton label="篩選標籤" hasRemoved values={queryTagIds} onChange={setQueryTagIds} />
        {isOpenWebAdd ? <CaseOneNoteAdd
            isInline={true}
            onCancel={()=> setIsAdding(false) }
            onSaved={() => setIsAdding(false) }
        /> : <CaseOneNoteAddBoard tagIds={queryTagIds} setTagIds={setQueryTagIds} onClick={()=>{
            setIsAdding(true);
        }} />
        }
        {notes.filter(filterByTagIds).sort(({written_date: date1}, {written_date: date2})=>{
            return new Date(date2) - new Date(date1);
        }).map(note => {
            if(isEditing && note.id === editNoteId){
                return <CaseOneNoteEdit
                    key={note.id}
                    isInline={true}
                    id={editNoteId}
                    onCancel={()=> setIsEditing(false) }
                    onSaved={() => setIsEditing(false) }
                />
            }
            else{
                return <CaseOneNoteView key={note.id} value={note} onEdit={id=>{
                    setEditNoteId(id);
                    setIsEditing(true);
                }} />
            }
        })}
    </Panel>
};

export default CaseOneNotes;
