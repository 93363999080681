import {useMutation, useQuery, useQueryClient} from "react-query";
import utils from "../utils";
import queryKeys from "./queryKeys";
import getPromise from "./getPromise";
import ajax from "../actions/ajax";
import useCaseNum from "../components/CaseOne/shared/useCaseNum";

const prefix_url = "/case";
const URGENT_LEVEL = "urgent_level";
const IS_PUBLIC = "is_public";
const WHISTLEBLOWER_PHONE = "whistleblower_phone";
const WHISTLEBLOWER_EMAIL = "whistleblower_email";
const STATUS = "status";
const UNDERTAKER = "undertaker";
const OVERALL_BRIEF = "overall_brief";
const CURRENT_BRIEF = "current_brief";
const DROPBOX_URL = "dropbox_url";
const TYPE_ID = "type_id";
const ANIMAL_LOCATION_CITY = "animal_location_city";
const ANIMAL_LOCATION_AREA = "animal_location_area";
const ANIMAL_LOCATION_ROAD = "animal_location_road";
const ANIMAL_LOCATION_DETAILS = "animal_location_details";

export const getCaseAllPromise = () => {
    return getPromise(prefix_url, {filters: utils.get_filters()});
}

export const getCaseOnePromise = (case_num) => {
    return getPromise(`${prefix_url}/${case_num}`);
}

export const useCaseList = ()=>{
    return useQuery([queryKeys.CASE_ALL, utils.get_filters()], () => getCaseAllPromise(), {
        refetchOnMount: true
    });
}

export const useCaseOne = () => {
    const caseNum = useCaseNum();
    return useQuery([queryKeys.CASE_ONE, caseNum], () => getCaseOnePromise(caseNum), {
        refetchOnMount: true
    });
};

export const useCaseYear = ()=>{
    return useQuery(queryKeys.CASE_YEAR_ALL, () => getPromise(`${prefix_url}/year`));
}

export const useCaseUndertakers = ()=>{
    return useQuery(queryKeys.CASE_UNDERTAKER_ALL, () => getPromise(`${prefix_url}/undertaker`));
}

export const useUpdateIsPublic = ()=>{
    return useUpdateCaseOneColumnMutation(IS_PUBLIC);
}

export const useUpdateStatus = ()=>{
    return useUpdateCaseOneColumnMutation(STATUS);
}

export const useUpdateUrgentLevel = ()=>{
    return useUpdateCaseOneColumnMutation(URGENT_LEVEL);
}

export const useUpdateUndertaker = ()=>{
    return useUpdateCaseOneColumnMutation(UNDERTAKER);
}

export const useUpdateOverallBrief = ()=>{
    return useUpdateCaseOneColumnMutation(OVERALL_BRIEF);
}

export const useUpdateCurrentBrief = ()=>{
    return useUpdateCaseOneColumnMutation(CURRENT_BRIEF);
}

export const useUpdateDropboxUrl = ()=>{
    return useUpdateCaseOneColumnMutation(DROPBOX_URL);
}

export const useUpdateTypeId = ()=>{
    return useUpdateCaseOneColumnMutation(TYPE_ID);
}

export const useUpdateAnimalLocationCity = ()=>{
    return useUpdateCaseOneColumnMutation(ANIMAL_LOCATION_CITY);
}

export const useUpdateAnimalLocationArea = ()=>{
    return useUpdateCaseOneColumnMutation(ANIMAL_LOCATION_AREA);
}

export const useUpdateAnimalLocationRoad = ()=>{
    return useUpdateCaseOneColumnMutation(ANIMAL_LOCATION_ROAD);
}

export const useUpdateAnimalLocationDetails = ()=>{
    return useUpdateCaseOneColumnMutation(ANIMAL_LOCATION_DETAILS);
}

const updatePromise = ({case_num, column, value}) => {
    return ajax.put(`${prefix_url}/${case_num}/${column}`, {value});
};

const useUpdateCaseOneColumnMutation = (column) => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation([queryKeys.CASE_ONE, case_num, column],value=> {
        return updatePromise({case_num, column, value});
    }, {
        onSuccess: async data => {
            queryClient.setQueryData([queryKeys.CASE_ONE, case_num], data);
        },
    });
};

export const useUpdateCaseAllIsPublic = () => {
    return useUpdateCaseAllById(IS_PUBLIC);
};

export const useUpdateCaseAllStatus = () => {
    return useUpdateCaseAllById(STATUS);
};

export function useDeleteCase() {
    const queryClient = useQueryClient();
    return useMutation(id => {
        return ajax.delete(`${prefix_url}/${id}`);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.CASE_ALL);
            utils.openNotification("刪除成功！");
        },
    });
}

export const useUpdateWhistleblowerPhone = () => {
    return useUpdateCaseOneColumnMutation(WHISTLEBLOWER_PHONE);
};

export const useUpdateWhistleblowerEmail = () => {
    return useUpdateCaseOneColumnMutation(WHISTLEBLOWER_EMAIL);
};

const useUpdateCaseAllById = (column) => {
    const queryClient = useQueryClient();

    return useMutation(({value, case_num}) => {
        return updatePromise({column, case_num, value});
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.CASE_ALL);
            utils.openNotification("更新成功！");
        },
    });
};