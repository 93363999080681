import React, {useState} from 'react';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import styled, {css} from "styled-components";
import UserAvatar from "../../../Shared/UserAvatar";
import borderStyle from "../shared/borderStyle";
import TagChips from "../shared/TagChips";
import utils from "../../../../utils";
import HTMLRenderer from "./HTMLRenderer";
import TrashCanButton from "../../../Shared/TrashCanButton";
import PenButton from "../../../Shared/PenButton";
import {useDeleteMutation} from "../../../../queries/caseNote";
import IconButtonWithText from "../../../Shared/IconButtonWithText";

const Container = styled.div`
  ${borderStyle};
  padding: .5rem;
  display: grid;
  grid-template-columns: 5rem 5rem repeat(3, 1fr);
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: 
      "avatar time time time time"
      ".      tags tags tags tags"
      "text   text text text text"
      "delete edit .    .    arrow";
  
  > *:nth-child(1) {
    grid-area: avatar;
  }
  
  > *:nth-child(2) {
    grid-area: time;
  }
  
  > *:nth-child(3) {
    grid-area: tags;
  }
  
  > *:nth-child(4) {
    grid-area: text;
  }
  
  > *:nth-child(5) {
    grid-area: delete;
  }
  
  > *:nth-child(6) {
    grid-area: edit;
  }
  
  > *:nth-child(7) {
    grid-area: arrow;
  }
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #434242;
  font-size: 1rem;
`;

const Text = styled.div`
  word-break: break-word;
  padding-top: 5px;
  padding-bottom: 5px;

  ${props => !props.open && css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;  
  `};
  
  p{
    margin: 5px;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  user-select: none;
`;

const CaseOneNoteView = ({className, value: note, onEdit=()=>{}}) => {
    const [open, setOpen] = useState(false);
    const deleteMutation = useDeleteMutation();

    return (
        <Container className={className}>
            <UserAvatar aria-label="recipe" color={note.user_color} value={note.user_display_name} />
            <Time>{utils.toTaipeiTimeStr(note.written_date)}</Time>
            <div>
                <TagChips values={note.tag_ids} />
            </div>
            <Text open={open}>
                <div dangerouslySetInnerHTML={{__html: HTMLRenderer.render(note.text)}} />
            </Text>
            <IconButtonWithText text="刪除" onClick={()=>{
                if (window.confirm("確定刪除此筆資料?")) {
                    deleteMutation.mutate(note.id);
                }}
            }>
                <TrashCanButton/>
            </IconButtonWithText>
            <IconButtonWithText text="編輯" onClick={()=>onEdit(note.id)}>
                <PenButton/>
            </IconButtonWithText>

            <ArrowContainer onClick={()=>{
                setOpen(open=>!open);
            }}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ArrowContainer>
        </Container>
    );
}

export default CaseOneNoteView;
