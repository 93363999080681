import _ from "lodash";
import {useCallback, useEffect, useRef, useState} from "react";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useDebounceValue = (originalValue, onChange) => {
    const prevValue = usePrevious(originalValue)
    const [value, setValue] = useState(originalValue);
    const debounceChange = useCallback(_.debounce(value => onChange(value), 500),[]);

    useEffect(() => {
        if(originalValue !== prevValue){
            setValue(originalValue);
        }
    }, [prevValue, originalValue]);

    return {value, setValue: (v, isComposing=false)=>{
        setValue(v);
        if(!isComposing){
            debounceChange(v);
        }
    }};
};

export default useDebounceValue;
