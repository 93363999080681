import React from "react";
import cities from './cities';
import DropdownList from "../../../Shared/DropdownList";
import {DEFAULT_SELECT_VALUE} from "../../../../config";
import {
    useUpdateAnimalLocationArea,
    useUpdateAnimalLocationCity,
    useUpdateAnimalLocationDetails,
    useUpdateAnimalLocationRoad
} from "../../../../queries/case";

const CityList = ({animal_location_city}) => {
    const updateAnimalLocationCity = useUpdateAnimalLocationCity();
    const updateAnimalLocationArea = useUpdateAnimalLocationArea();
    const updateAnimalLocationRoad = useUpdateAnimalLocationRoad();
    const updateAnimalLocationDetails = useUpdateAnimalLocationDetails();

    const options = [{label: "選擇縣市", value: DEFAULT_SELECT_VALUE}];
    cities.forEach((value) => {
        options.push({value, label: value});
    });
    const disabled = updateAnimalLocationCity.isLoading || updateAnimalLocationArea.isLoading || updateAnimalLocationRoad.isLoading || updateAnimalLocationDetails.isLoading;

    return <DropdownList
        id="CityList"
        disabled={disabled}
        value={animal_location_city}
        options={options}
        onChange={value=>{
            if(value !== animal_location_city){
                updateAnimalLocationCity.mutate(value);
                updateAnimalLocationArea.mutate(DEFAULT_SELECT_VALUE);
                updateAnimalLocationRoad.mutate(DEFAULT_SELECT_VALUE);
                updateAnimalLocationDetails.mutate("");
            }
        }}
    />;
};

export default CityList;

CityList.propTypes = {
};
