import React, {Fragment} from "react";
import styled from "styled-components";
import _Card from "./Card";
import {useCaseList} from "../../../queries/case";
import useIsWeb from "../../../hooks/useIsWeb";

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: ${props=> props.isWeb? "repeat(4, 1fr)": "1fr 1fr"};
`;

const Card = styled(_Card)`
  margin: 5px;
  height: 5rem;
`;

const CaseListDashBoard = ()=>{
    const { isLoading, data } = useCaseList();
    const isWeb = useIsWeb();
    if(isLoading) return <Fragment />

    const {total_count: totalCount, processing_count: processingCount, pending_count: pendingCount, finished_count: finishedCount } = data.data;

    return <CardContainer isWeb={isWeb}>
        <Card label="總案件" count={totalCount} />
        <Card label="進行中" count={processingCount} />
        <Card label="待結案" count={pendingCount} />
        <Card label="已完成" count={finishedCount} />
    </CardContainer>
};

export default CaseListDashBoard;
