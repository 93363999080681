import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel} from "@material-ui/core";
import S from '@material-ui/core/Switch';

const handleChange = (onChange=()=>{}) => {
    return (event)=>{
        onChange(event.target.checked);
    };
};

function Switch({ className, label="", disabled=false, checked=false, value="", onChange=()=>{} } ) {
    return <FormControlLabel
        label={label}
        control={
            <S
                className={className}
                checked={checked}
                value={value}
                disabled={disabled}
                onChange={handleChange(onChange)}
            />
        }
    />
}

Switch.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool
};

export default Switch;
