import _ from "lodash";
import React, {Fragment, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import PasswordField from "../Shared/PasswordField/PasswordField";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import FormControl from "@material-ui/core/FormControl/FormControl";
import validateUtil from "../../utils/validateUtil";

function is_disabled(pw1 = "", pw2 = "") {
    if (_.isEmpty(pw1) || _.isEmpty(pw2)) {
        return true;
    }
    else if (!_.isEqual(pw1, pw2)) {
        return true;
    }
    else if (!_.isEmpty(get_error(pw1, pw2))){
        return true;
    }
    return false;
}


function get_error(pw_1, pw_2){
    if(!_.isEqual(pw_1, pw_2)){
        return "兩次密碼輸入不正確";
    }
    else if(!validateUtil.is_password(pw_1)){
        return validateUtil.get_password_error(pw_1);
    }
    return "";
}

const ChangePasswordDialog = ({open=false, onSubmit=()=>{}, onClose=()=>{}})=>{
    const [pw1, setPw1] = useState("");
    const [pw2, setPw2] = useState("");

    if(!open) return <Fragment />

    return <div>
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">更改密碼</DialogTitle>
            <DialogContent>
                <form style={{display: "flex", flexDirection: "column"}}>
                    <input hidden type="text" name="username" autoComplete="username" />
                    <PasswordField
                        label="輸入新的密碼"
                        value={pw1}
                        onChange={value=>setPw1(value)}
                    />
                    <PasswordField
                        label="重新輸入新密碼"
                        value={pw2}
                        onChange={value=>setPw2(value)}
                    />
                    <FormControl error>
                        <FormHelperText id="component-error-text">{get_error(pw1, pw2)}</FormHelperText>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    取消
                </Button>
                <Button color="primary" disabled={is_disabled(pw1, pw2)} onClick={()=> onSubmit(pw1, pw2)}>
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}

ChangePasswordDialog.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func
};

export default ChangePasswordDialog;
