import React, {memo} from 'react';
import PropTypes from 'prop-types';
import useEnabledPendingStatus from "../../../../hooks/useEnabledPendingStatus";
import useEnabledFinishedStatus from "../../../../hooks/useEnabledFinishedStatus";
import StatusSelect from "../../../../components/StatusSelect";
import {useUpdateCaseAllStatus} from "../../../../queries/case";

const CaseListStatusSelect = memo(({caseNum, value})=>{
    const mutation = useUpdateCaseAllStatus();
    const enabledPending = useEnabledPendingStatus()
    const enabledFinished = useEnabledFinishedStatus();

    return <StatusSelect
        value={value}
        disabled={mutation.isLoading}
        enabledFinished={enabledFinished}
        enabledPending={enabledPending}
        onChange={value=>{
            mutation.mutate({value, case_num: caseNum});
        }}
    />
});

CaseListStatusSelect.defaultProps = {
    value: "",
    onChange: ()=>{},
};

CaseListStatusSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default CaseListStatusSelect;
