import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import logo from "./spca_logo.svg";
import {APP_NAME, INDEX_URL} from "../../../config";

const Container = styled(Link)`
    display: flex;
`;

export const LogoImage = ()=>{
    return <img alt={APP_NAME} src={logo} />
}

const Logo = ({className}) => {
    return <Container className={className} to={INDEX_URL}>
        <LogoImage />
    </Container>
};

export default Logo;
