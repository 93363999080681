import styled from "styled-components";
import {IoIosAddCircle} from "react-icons/io";

const PlusIcon = styled(IoIosAddCircle)`
  color: #79B256;
  font-size: 1.875rem;  
  cursor: pointer;
`

export default PlusIcon;
