import _ from "lodash";
import axios from "axios";
import {API_URL} from "../config";
import {get_token} from "./token";

const token = get_token();
const headers = {};

if(!_.isEmpty(token)){
    headers.token = token;
}
export default axios.create({
    baseURL: API_URL,
    headers
});