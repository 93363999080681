import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {KodenCategoryCrud} from "@scott/koden.koden-category-crud";
import Title from "../components/Title";
import DefaultContainer from "../components/DefaultContainer";

const Container = styled.div`
    &:nth-of-type(1) > *:nth-of-type(1) > *:nth-of-type(1)> *:nth-of-type(1){
      padding: 0;
    }
  & > * > * > * > * > div > * > * {
    div div div {
      min-width: 230px;
      
      @media (min-width: 960px) {
        min-width: 600px;
      }
    }
  }
`

function OptionListRoute({title, resource}){
    return <DefaultContainer>
        <Title>{title}</Title>
        <Container>
            <KodenCategoryCrud
                resource={resource}
                title={title}
                addColumns={[
                    {
                        id: "name",
                        defaultValue: "",
                        label: "",
                        autoFocus: true,
                        placeholder: "",
                        emptyError: "",
                    },
                ]}
                listBackgroundColor="#EAEAEA"
                listColumns={[
                    {
                        id: "name",
                        defaultValue: "",
                    },
                ]}
            />
        </Container>
    </DefaultContainer>
}

OptionListRoute.propTypes = {
    title: PropTypes.string,
    resource: PropTypes.string,
};

export default OptionListRoute;
