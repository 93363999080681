import CaseList_Route, {route_path as case_list_path} from './CaseList_Route';
import UserList_Route, {route_path as user_list_path} from './UserList_Route';
import UserAdd_Route, {route_path as user_add_path} from './UserAdd_Route';
import Login_Route, {route_path as login_path} from './Login_Route';
import CaseOne_Route, {route_path as case_one_path} from './CaseOne_Route';
import AnimalSpeciesList_Route, {route_path as animal_species_path} from './AnimalSpeciesList_Route';
import ComplaintCategoryList_Route, {route_path as complaint_category_list_path} from './ComplaintCategoryList_Route';
import CurrentBriefList_Route, {route_path as current_brief_list_path} from './CurrentBriefList_Route';
import Analysis_Route, {route_path as analysis_path} from './Analysis_Route';
import TagList_Route, {route_path as tag_list_path} from "./TagList_Route";
import CaseTypeList_Route, {route_path as case_type_list_path} from "./CaseTypeList_Route";

export default [
    {
        exact: true,
        path: login_path,
        component: Login_Route,
        layout: 'MainLayout',
        is_login_required: false,
        is_app_bar: false,
        background_color: "#041C35"
    },
    {
        exact: true,
        path: case_list_path,
        component: CaseList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: ({isMobile})=>{
            return isMobile ? "white" : "#F0F2F8";
        }
    },
    {
        exact: true,
        path: case_one_path,
        component: CaseOne_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "#F0F2F8"
    },
    {
        exact: true,
        path: user_list_path,
        component: UserList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: ({isMobile})=>{
            return isMobile ? "white" : "#F0F2F8";
        }
    },
    {
        exact: true,
        path: user_add_path,
        component: UserAdd_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    },
    {
        exact: true,
        path: tag_list_path,
        component: TagList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    },
    {
        exact: true,
        path: case_type_list_path,
        component: CaseTypeList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    },
    {
        exact: true,
        path: animal_species_path,
        component: AnimalSpeciesList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    },
    {
        exact: true,
        path: complaint_category_list_path,
        component: ComplaintCategoryList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    },
    {
        exact: true,
        path: current_brief_list_path,
        component: CurrentBriefList_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    },
    {
        exact: true,
        path: analysis_path,
        component: Analysis_Route,
        layout: 'MainLayout',
        is_login_required: true,
        is_app_bar: true,
        background_color: "white"
    }
]
