import _ from "lodash";
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";

const Form = styled.form`
  width: 100%;
`

const FormControl = styled(_FormControl)`
  width: 100%;
`

const handleChange = (onChange=()=>{}) => {
    return (event)=>{
        onChange(event.target.value);
    };
};

const DropdownList = memo(({className, hasDefaultLabel=false, disabled=false, classes={}, options=[], label="", value="", onChange=()=>{}})=>{
    options = options.map(option=>{
        if(_.isString(option)){
            return { label: option, value: option };
        }
        else{
            return option;
        }
    });

    const selectValue = (value === 0 || value) ? value : '';

    return (
        <Form autoComplete="off" className={className}>
            <FormControl className={classes.FormControl}>
                {hasDefaultLabel && <InputLabel>{label}</InputLabel>}
                <Select native disabled={disabled} value={selectValue} onChange={handleChange(onChange)}>
                    {
                        options.map(({value, label, disabled=false}, index) => {
                            return <option disabled={disabled} key={`MenuItem_${value}_${index}`} value={value}>{label}</option>
                        })
                    }
                </Select>
            </FormControl>
        </Form>
    );
});

DropdownList.propTypes = {
    classes: PropTypes.object,
    hasDefaultLabel: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool
};

export default DropdownList;
