import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const LabelOption = ({value, checked, label, onChange}) => {
    return <FormControlLabel
        control={
            <Checkbox
                checked={checked}
                onChange={onChange}
                name={value}
                color="default"
            />
        }
        label={label}
    />
};


export default  LabelOption;