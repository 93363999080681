export const MALE = '男';
export const FEMALE = '女';

const map = {
    MALE,
    FEMALE
};

export function get_label(value=""){
    return map[value.toUpperCase()] || "";
}