import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({children, open, onClose}) => {
    return <Dialog fullScreen disableEnforceFocus open={open} onClose={onClose} TransitionComponent={Transition}>
        {children}
    </Dialog>
};

export default FullScreenDialog;