import _ from "lodash";
import React, {useState} from "react";
import Panel from "../Panel/Panel";
import {Grid} from "@material-ui/core";
import TextField from "../Shared/TextField/TextField";
import RaisedButton from "../Shared/RaisedButton/RaisedButton";
import {redirect_route, route_path} from "../../routes/UserList_Route";
import PasswordField from "../Shared/PasswordField/PasswordField";
import utils from '../../utils/utils';
import validateUtil from "../../utils/validateUtil";
import RoleSelect from "../RoleSelect";
import roles from "../../consts/roles";
import {useUserAdd} from "../../queries/user";
import queryKeys from "../../queries/queryKeys";
import {DEFAULT_PAGE} from "../../config";
import {useQueryClient} from "react-query";
import DefaultContainer from "../DefaultContainer";

function get_submit_label({display_name = "", email = "", password = ""}){
    if(_.isEmpty(display_name)){
        return "請輸入名稱";
    }
    else if(_.isEmpty(email)){
        return "請輸入 Email";
    }
    else if(!utils.is_email_format(email)){
        return "Email格式不正確";
    }
    else if(!validateUtil.is_password(password)){
        return validateUtil.get_password_error(password);
    }
    return "送出";
}

const UserAdd = (()=>{
    const queryClient = useQueryClient();
    const userAdd = useUserAdd();
    const role = roles.INVESTIGATOR;
    const [display_name, setDisplayName]= useState("");
    const [email, setEmail]= useState("");
    const [password, setPassword]= useState("");

    const disabled = _.isEmpty(display_name) || _.isEmpty(email) || !utils.is_email_format(email) || _.isEmpty(password) || !validateUtil.is_password(password);

    return <DefaultContainer>
        <Panel title="帳號新增">
            <Grid container spacing={5}>
                <Grid item sm={12}>
                    <TextField label="名稱" value={display_name} onChange={value=>setDisplayName(value)} />
                </Grid>
                <Grid item sm={12}>
                    <RoleSelect disabled={true} value={role} />
                </Grid>
                <Grid item sm={12}>
                    <TextField label="Email" value={email} onChange={value=>setEmail(value)} />
                </Grid>
                <Grid item sm={12}>
                    <PasswordField label="密碼" value={password} onChange={value=>setPassword(value)} />
                </Grid>
                <Grid item sm={12}>
                    <RaisedButton onClick={redirect_route}>返回</RaisedButton>
                    <RaisedButton disabled={disabled} onClick={()=>{
                        userAdd.mutate({display_name, role, email, password}, {async onSuccess(){
                                await queryClient.invalidateQueries(queryKeys.USER_ALL);
                                utils.openNotification("新增成功");
                                utils.changePage({route_path, page: DEFAULT_PAGE});
                            }});
                    }}>{get_submit_label({display_name, email, password})}</RaisedButton>
                </Grid>
            </Grid>
        </Panel>
    </DefaultContainer>
});

export default UserAdd;

UserAdd.propTypes = {
};
