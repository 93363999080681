import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import MaterialAvatar from "@material-ui/core/Avatar";

const Avatar = styled(MaterialAvatar)`
  background-color: ${props=>props.color};
`

const getAvatarName = (value) => {
    if(_.isEmpty(value) || _.isEmpty(value)){
        return "A";
    }
    return value[0];
};

const UserAvatar = ({value, color})=>{
    const avatarName = getAvatarName(value);

    return <Avatar color={color}>{avatarName}</Avatar>;
};

UserAvatar.defaultProps = {
    value: "",
    color: "#F2ABAB"
};

UserAvatar.propTypes = {
    value: PropTypes.string
};
export default UserAvatar;
