import _ from "lodash";
import React, {memo} from "react";
import PropTypes from "prop-types";
import ErrorMsg from "../ErrorMsg";

const CaseOneEmpty = memo(({case_num}) => <ErrorMsg>
    {_.isEmpty(case_num) ? '找不到此案件' : `找不到案件${case_num}`}
</ErrorMsg>);

CaseOneEmpty.propTypes = {
    case_num: PropTypes.string
};

export default CaseOneEmpty;
