import {useMutation} from "react-query";
import ajax from "../actions/ajax";
import {set_token} from "../actions/token";
import utils from "../utils";
import queryKeys from "./queryKeys";

const prefix_url = "/auth";

export const useLoginMutation = () => {
    return useMutation(queryKeys.AUTH_LOGIN, ({email, password}) => ajax.post(prefix_url, {email, password}),
        {
            onSuccess({data: {token}}){
                set_token(token);
                utils.redirect_href_index();
            },
            onError({response}){
                window.alert(utils.getErrorObj(response).msg);
            }
        }
    );
}