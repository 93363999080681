import React from "react";
import PropTypes from "prop-types";
import {useCaseUndertakers} from "../queries/case";
import UndertakerSelectBase from "./undertakerSelectBase";

const CaseUndertakerSelect = ({isKoden=false, value, onChange}) => {
    const {data, isLoading, isError} = useCaseUndertakers();

    return <UndertakerSelectBase
        data={data}
        isKoden={isKoden}
        isLoading={isLoading}
        isError={isError}
        showLabel
        showUnAssigned
        showOnlyCaseUndertakers
        value={value}
        onChange={onChange}
    />
}

CaseUndertakerSelect.defaultProps = {
    value: "",
    onChange: ()=>{}
};

CaseUndertakerSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default CaseUndertakerSelect;
