import FlexBox from "../components/FlexBox";
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Container = styled(FlexBox)`
  cursor: pointer;
  padding: 1rem;
  
  &:hover{
    background-color: #E9E9E9;
  }
`

const LeftAndRight = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  width: 100%;
`;

const iconStyle = {
    width: 26,
    height: 26,
    color: "#818080"
};

const IconAndText = ({open, icon, txt, onClick}) => {
    return <Container onClick={onClick} title={txt}>
        {!open ?
            <Icon src={icon} alt={txt} style={iconStyle} />
            :
            <LeftAndRight>
                <FlexBox style={{width: 40}}>
                    <Icon src={icon} alt={txt} style={{...iconStyle, margin: "0 auto"}} />
                </FlexBox>
                <FlexBox style={{justifyContent: "left", marginLeft: "1rem"}}>
                    {txt}
                </FlexBox>
            </LeftAndRight>}
    </Container>
};

export default IconAndText;
