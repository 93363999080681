import _ from "lodash";
import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import Header from "./Header";
import Drawer from "./Drawer";
import useMe from "../hooks/useMe";
import useIsWeb from "../hooks/useIsWeb";
import Sidebar from "./Sidebar";
import isShowComponent from "./isShowComponent";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--header-height) 1fr;
  font-size: 16px;
`;

const ComponentContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.background_color};
  margin-top: auto;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - var(--header-height));
  overflow-x: auto;
  
  ${props => props.hasSideBar && css`
    margin-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
  `}
`;

const Content = (({is_app_bar=false, is_login_required=false, component: Component, background_color})=>{
    const me = useMe();
    const isWeb = useIsWeb();
    const [open, setOpen] = useState(false);
    const Parent = is_app_bar ? Container: Fragment;

    const hasSideBar = !_.isEmpty(me) && isWeb;
    return <Parent>
        {is_app_bar && <Header onHamburgerClick={()=> setOpen(!open)} />}
        {!isWeb && <Drawer open={open} onClose={()=> setOpen(false)} />}
        {!_.isEmpty(me) && isWeb && <Sidebar open={open} />}
        <ComponentContainer hasSideBar={hasSideBar} background_color={background_color} open={open}>
            {isShowComponent({is_login_required, me}) && <Component />}
        </ComponentContainer>
    </Parent>
});

Content.propTypes = {
    is_app_bar: PropTypes.bool,
    is_login_required: PropTypes.bool,
};

export default Content;
