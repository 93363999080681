import React from "react";
import styled, {css} from "styled-components";
import utils from "../../../../utils";
import FlexBox from "../../../../components/FlexBox";

const getBackgroundColor = (value) => {
    switch(value){
        case "Routine":
            return "#6C87C6"
        case "Urgent":
            return "#E66C6C"
        case "Priority":
            return "#EDAA4F"
        default:
            return "";
    }
};

const Container = styled(FlexBox)`
  width: 87px;
  height: 1.5rem;
  font-size: 14px;
  border-radius: 4px;
  color: white;
  background-color: ${props=>props.backgroundColor};
  margin: 0 auto;
  ${props => props.hide && css`
    visibility: hidden;
  `};
`;

const Urgent = ({value}) => {
    const label = utils.getUrgentLevelLabel(value);
    const hide = value === 'Default';

    return <Container backgroundColor={getBackgroundColor(value)} hide={hide}>
        {label}
    </Container>
};

export default Urgent;
