import _ from "lodash";

const isShowComponent = ({is_login_required, me}) => {
    if(!is_login_required){
        return true;
    }
    else{
        return !_.isEmpty(me);
    }
};

export default isShowComponent;