import React from 'react';
import TrashCanButton from "../../../components/Shared/TrashCanButton";
import GoogleMapLink from "../../../components/Shared/GoogleMapLink";
import EllipsisText from "../../../components/Shared/EllipsisText";
import Urgent from "./Urgent";
import CaseListStatusSelect from "./CaseListStatusSelect";
import {get_route_path} from "../../CaseOne_Route";
import Link from "../../../components/Shared/Link";
import {useDeleteCase} from "../../../queries/case";
import useEnabledDeleteCount from "../../../hooks/useEnabledDeleteAccount";
import IsPublicSwitch from "./IsPublicSwitch";

const useCells = () => {
    const enabled_delete_case = useEnabledDeleteCount();
    const deleteCase = useDeleteCase();

    const onDelete = ({id, case_num}) => {
        if (window.confirm(`確定刪除${case_num}?`)) {
            deleteCase.mutate(id);
        }
    };

    const cell_components = enabled_delete_case ? [{
        align: "center",
        style: {
            minWidth: 65
        },
        component: ({id, case_num})=>{
            return <TrashCanButton onClick={()=>onDelete({id, case_num})} />
        }
    }]: [];

    return cell_components.concat([
        {
            align: "center",
            style: {
                width: 135
            },
            component: ({case_num})=>{
                return <Link to={get_route_path(case_num)}>{case_num}</Link>
            },
        },
        {
            align: "left",
            style: {
                width: "30ch",
                minWidth: "30ch"
            },
            component: ({animal_location})=>{
                return <GoogleMapLink address={animal_location} />
            },
        },
        {
            align: "left",
            style: {
                width: "40ch",
                minWidth: "40ch"
            },
            component: ({overall_brief})=>{
                return overall_brief;
            },
        },
        {
            align: "left",
            style: {
                minWidth: 100,
                maxWidth: 100
            },
            component: ({undertaker})=>{
                return <EllipsisText>
                    {undertaker}
                </EllipsisText>
            },
        },
        {
            align: "center",
            style: {
                minWidth: 145
            },
            component: ({urgent_level})=>{
                return <Urgent value={urgent_level} />
            },
        },
        {
            align: "center",
            style: {
                minWidth: 120,
            },
            component: ({status, case_num}) => <CaseListStatusSelect caseNum={case_num} value={status} />,
        },
        {
            align: "center",
            style: {
                minWidth: 120
            },
            component: ({case_num, is_public=false}) => <IsPublicSwitch caseNum={case_num} value={is_public} />
        }
    ]);
}

export default useCells;
