import React, {memo} from "react";
import PropTypes from 'prop-types';
import validateUtil from '../../utils/validateUtil';
import {PASSWORD_FORMAT} from '../../consts/msg';
import TextFieldWithError from "../Shared/TextFieldWithError";

const PasswordInput = memo(({input_test_id, label_test_id, error_test_id, value, onChange=()=>{}}) => {
    const is_password = validateUtil.is_password(value);
    const errorText = !is_password ? PASSWORD_FORMAT : '';

    return <TextFieldWithError
        id="password"
        label="密碼"
        type="password"
        input_test_id={input_test_id}
        label_test_id={label_test_id}
        error_test_id={error_test_id}
        value={value}
        onChange={onChange}
        errorText={errorText}
    />
});

export default PasswordInput;

PasswordInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    input_test_id: PropTypes.string,
    label_test_id: PropTypes.string,
    error_test_id: PropTypes.string
};
