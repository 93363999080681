import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import TagChip from "../TagChip";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  
   > * {
    margin-right: 10px;
    margin-bottom: 5px;
  }
`;

const TagChips = ({className, values, hasRemoved, onRemove}) => {
    return <Container className={className}>
        {values.map(id=> <TagChip key={id} hasRemoved={hasRemoved} value={id} onClick={()=>onRemove(id)} />)}
    </Container>
};

TagChips.defaultProps = {
    values: [],
    hasRemoved: false,
    onRemove: ()=>{}
};

TagChips.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
    hasRemoved: PropTypes.bool,
    onRemove: PropTypes.func
};

export default TagChips;
