import error_msg from "./error_msg_reducer";
import me from "./me_reducer";
import notification from "./notification_reducer";
import analysis from "./analysis_reducer";

const rootReducer = {
    error_msg,
    me,
    notification,
    analysis,
};

export default rootReducer;
