import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 6.25rem 1fr;
  
  &:not(:last-child) {
      margin-bottom: 0.8125rem;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Left = styled(FlexBox)`
  height: 100%;
  font-size: 0.875rem;
  color: #5C5A5A;
`;

const KeyValueField = (({className, left: Key, right: Value}) => {
    return <Container className={className}>
        <Left>
            {Key}
        </Left>
        <FlexBox>
            {Value}
        </FlexBox>
    </Container>
});

export default KeyValueField;

KeyValueField.propTypes = {
};
