import _ from "lodash";
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
    FormControl: {
        width: 150
    },
    ListItemText: {
        overflow: "auto"
    }
};

const MaterialMultipleSelect = ({id, disabled=false, className, renderValue, classes = {}, label = "", options = [], values: defaultValues = [], onChange=()=>{}}) => {
    const [values, setValues] = useState(defaultValues);

    useEffect(()=>{
        setValues(defaultValues);
    }, [defaultValues])

    const map = _.mapKeys(options, "value");
    const defaultRenderValue = values =>{
        return values.map(value => {
            return map[value]?.label;
        }).join(', ');
    }

    return (
        <FormControl fullWidth className={`${classes.FormControl} ${className}`}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                id={id}
                disabled={disabled}
                multiple
                value={values}
                onChange={event => setValues(event.target.value)}
                input={<Input />}
                renderValue={renderValue || defaultRenderValue}
                onClose={()=> onChange(values)}
            >
                {_.values(options).map((option={}) => {
                    const {value, label} = option;
                    const checked = !!values.find(e=>e===value);

                    return <MenuItem key={value} value={value}>
                        <Checkbox checked={checked} />
                        <ListItemText className={classes.ListItemText} primary={label} />
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    );
}

MaterialMultipleSelect.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    theme: PropTypes.object,
    label: PropTypes.string,
    values: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func,
    renderValue: PropTypes.func,
};

export default withStyles(styles)(MaterialMultipleSelect);
