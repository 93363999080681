import React from "react";
import styled, {css} from "styled-components";
import YearSelect from "./YearSelect";
import MonthSelect from "./MonthSelect";
import UndertakerSelect from "./UndertakerSelect";
import UrgentSelect from "./UrgentSelect";
import CaseListQueryStatusSelect from "./CaseListQueryStatusSelect";
import ClearAll from "./ClearAll";
import {getYear} from "./YearSelect/YearSelect";
import {DEFAULT_NUMBER_VALUE} from "../../../config";
import useIsWeb from "../../../hooks/useIsWeb";

const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  
  display: grid;
  
  ${props => props.isWeb? css`
    grid-template-columns: repeat(6, 150px);
    & > *:not(:last-child){
      margin-right: 20px;
    }
  `: css`
     grid-template-rows: 1fr;
      
      & > *:not(:last-child){
        margin-bottom: .5rem;
      }
    `};
`;

const CaseListQuery = () => {
    const isWeb = useIsWeb();
    const year = getYear();

    return <Container isWeb={isWeb}>
        <YearSelect />
        {year !== DEFAULT_NUMBER_VALUE && <MonthSelect />}
        <UndertakerSelect />
        <UrgentSelect />
        <CaseListQueryStatusSelect />
        <ClearAll />
    </Container>
};

export default CaseListQuery;
