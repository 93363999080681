import _ from "lodash";
import React, {memo} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import {Paper} from "@material-ui/core";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import usePage from "../../../hooks/usePage";
import Pagination from "../Pagination";

const StyledTableCell = styled(TableCell)`
    &.MuiTableCell-head{
      background-color: rgb(108, 135, 198);
      color: white;
      font-weight: bold;
    }
`;

const MyTable = ({headers=[], cells=[], records=[], totalCount = 0, onPageChange=()=>{}})=>{
    const page = usePage();
    const isNotFound = totalCount===0 && _.isEmpty(records);

    return <Paper>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map(({id, align, label}) => {
                            return <StyledTableCell key={id} align={align}>{label}</StyledTableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isNotFound > 0 && (
                        <TableRow>
                            <TableCell colSpan={headers.length}>找不到資料</TableCell>
                        </TableRow>
                    )}
                    {records.map(record =>
                        <TableRow key={record.id} hover>
                            {cells.map(({align, style, isEllipsis=false, component}, index) => {
                                return <TableCell key={`${record.id}_${index}`} style={style} align={align}>
                                    {component(record)}
                                </TableCell>
                            })}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        <Pagination
            page={page}
            totalCount={totalCount}
            onChange={onPageChange}
        />
    </Paper>
}

MyTable.propTypes = {
    headers: PropTypes.array,
    cells: PropTypes.array,
    records: PropTypes.array,
    totalCount: PropTypes.number,
    onPageChange: PropTypes.func
};

export default memo(MyTable, (props, nextProps)=>{
    return _.isEqual(props.records, nextProps.records);
});
