import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function TextButton({ disabled=false, variant, children="文字", color="primary", onClick=()=>{} } ) {
    return <Button disabled={disabled} variant={variant} color={color} onClick={onClick}>
        {children}
    </Button>
}

TextButton.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    color: PropTypes.string,
    variant: PropTypes.string
};

export default TextButton;