import React, {memo} from 'react';
import Loading from "../Loading/Loading";
import CenterCenter from "../CenterCenter/CenterCenter";

const CenterLoading = memo(()=>{
    return <CenterCenter>
        <Loading />
    </CenterCenter>
});

CenterLoading.propTypes = {
};

export default CenterLoading;
