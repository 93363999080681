import styled from "styled-components";

const DefaultContainer = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  width: 100%;
  max-width: calc( 1024px - var(--sidebar-width) - 12px);
  
  @media (min-width: 1366px) {
    max-width: calc( 1366px - var(--sidebar-width) - 12px);
  }
`;

export default DefaultContainer;
