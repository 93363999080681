import React from "react";
import PropTypes from "prop-types";
import useIsMobile from "../../../../../hooks/useIsMobile";
import MobileTagSelect from "./MobileTagSelect";
import PcTagSelect from "./PcTagSelect";

const TagSelect = ({id:defaultId="", hasRemoved=false, isLabelButton=false, label="", values=[], onChange}) => {
    const isMobile = useIsMobile();
    if(isMobile){
        return <MobileTagSelect
            id={defaultId}
            values={values}
            onChange={onChange}
            label={label}
        />
    }
    else{
        return <PcTagSelect
            id={defaultId}
            isLabelButton={isLabelButton}
            hasRemoved={hasRemoved}
            values={values}
            onChange={onChange}
            label={label}
        />
    }
};

TagSelect.defaultProps = {
    label: "標籤",
    values: [],
    onChange: ()=>{}
};

TagSelect.propTypes = {
    label: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
};

export default TagSelect;
