const queryKeys = {
    AUTH_LOGIN: "AUTH_LOGIN",
    CASE_ONE: "CASE_ONE",
    CASE_ALL: "CASE_ALL",
    CASE_YEAR_ALL: "CASE_YEAR_ALL",
    CASE_TYPE_ALL: "CASE_TYPE_ALL",
    ADDRESS_ALL: "ADDRESS_ALL",
    ANIMAL_SPECIES_ALL: "ANIMAL_SPECIES_ALL",
    COMPLAINT_CATEGORY_ALL: "COMPLAINT_CATEGORY_ALL",
    CURRENT_BRIEF_ALL: "CURRENT_BRIEF_ALL",
    UNDERTAKER_ALL: "UNDERTAKER_ALL",
    CASE_UNDERTAKER_ALL: "CASE_UNDERTAKER_ALL",
    TAG_ALL: "TAG_ALL",
    USER_ALL: "USER_ALL",
    CASE_PERSONNEL_UPDATE: "CASE_PERSONNEL_UPDATE",
    CASE_PERSONNEL_DELETE: "CASE_PERSONNEL_DELETE",
    CASE_PERSONNEL_ADD: "CASE_PERSONNEL_ADD",
    CASE_REPORTER_PHONE: "CASE_REPORTER_PHONE",
    CASE_REPORTER_EMAIL: "CASE_REPORTER_EMAIL",
    CASE_ANIMAL_DETAIL_UPDATE: "CASE_ANIMAL_DETAIL_UPDATE",
    CASE_ANIMAL_DETAIL_DELETE: "CASE_ANIMAL_DETAIL_DELETE",
    CASE_ANIMAL_DETAIL_ADD: "CASE_ANIMAL_DETAIL_ADD",
    CASE_FILE_DELETE: "CASE_FILE_DELETE",
    CASE_FILE_ADDITIONAL_DELETE: "CASE_FILE_ADDITIONAL_DELETE",
    CASE_FILE_ADDITIONAL_ADD: "CASE_FILE_ADDITIONAL_ADD",
    CASE_NOTE_ONE: "CASE_NOTE_ONE",
    CASE_NOTE_DELETE: "CASE_NOTE_DELETE",
    CASE_NOTE_UPDATE: "CASE_NOTE_UPDATE",
    CASE_NOTE_ADD: "CASE_NOTE_ADD",
};

export default queryKeys;
