import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import SuccessSnackbar from "../components/SuccessSnackbar";
import {onClose} from "../actions/notification";

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
};

const styles = theme => ({
    marginTop: {
        marginTop: theme.spacing(1),
    }
});

const BaseComponent = memo(({open = false, message = "", onClose=()=>{}})=>{
    return <Snackbar
        autoHideDuration={1000}
        anchorOrigin={anchorOrigin}
        open={open}
        onClose={onClose}
    >
        <SuccessSnackbar
            variant="success"
            onClose={onClose}
            message={message}
        />
    </Snackbar>
});

function mapStateToProps({notification:{open, message}}) {
    return { open, message};
}

const Notification = withStyles(styles)(BaseComponent);

export default connect(mapStateToProps, {onClose})(Notification);

BaseComponent.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func
};
