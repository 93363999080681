import React from "react";
import PropTypes from "prop-types";
import DropdownList from "../components/Shared/DropdownList";
import CenterLoading from "../components/CenterLoading/CenterLoading";
import KodenSelect from "../components/KodenSelect/KodenSelect";

const UndertakerSelectBase = ({data, disabled, isKoden, isLoading, isError, showLabel, showUnAssigned, value, onChange}) => {
    if (isLoading || isError) return <CenterLoading/>
    else {
        let options = [];
        if(showLabel){
            options.push({value: "", label: "承辦人"});
        }
        if(showUnAssigned){
            options.push({value: "null", label: "尚未指派"});
        }
        options = options.concat(data.data.map(({id: value, name: label})=>{
            return {value, label};
        }));

        const Component = isKoden ? KodenSelect : DropdownList;

        return <Component
            label={showLabel?"承辦人": ""}
            disabled={disabled}
            value={value}
            options={options}
            onChange={onChange}
        />
    }
}

UndertakerSelectBase.defaultProps = {
    disabled: false,
    isKoden: false,
    value: "",
    onChange: ()=>{},
    showLabel: false,
    showUnAssigned: false
};

UndertakerSelectBase.propTypes = {
    disabled: PropTypes.bool,
    isKoden: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    shownUnAssigned: PropTypes.bool,
};

export default UndertakerSelectBase;
