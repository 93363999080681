import React from "react";
import PropTypes from "prop-types";
import Aditor from 'aditor';

function RichTextField({id, defaultValue, onChange}){
    return <Aditor
        id={id}
        defaultValue={defaultValue}
        onChange={onChange}
    />
}

RichTextField.defaultProps = {
    defaultValue: [],
    onChange: ()=>{}
};

RichTextField.propTypes = {
    id: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func
};

export default RichTextField;
