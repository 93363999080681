import React from 'react';
import styled from 'styled-components';
import {SwatchesPicker} from 'react-color';
import Popover from "@material-ui/core/Popover";
import RaisedButton from '../RaisedButton/RaisedButton';

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Color = styled.div`
  color: ${props => props.color};
  font-size: 2rem;
`

const ColorButton = ({color, onChangeComplete}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const close = () => {
        setAnchorEl(null);
    };

    return <Container>
        <RaisedButton color="primary" onClick={event=>{
            setAnchorEl(event.currentTarget);
        }}>選擇顏色</RaisedButton>
        <Color color={color}>●</Color>

        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <SwatchesPicker color={color} onChangeComplete={color=>{
                close();
                onChangeComplete(color.hex);
            }}  />
        </Popover>
    </Container>
};

ColorButton.propTypes = {};

export default ColorButton;
