import React from "react";
import styled from "styled-components";
import PlusIcon from "../CaseOne/shared/PlusIcon";

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled(PlusIcon)`
  margin-right: .5rem;
  cursor: pointer;
  user-select: none;
`

const AddButtonWithLabel = ({onClick, label="新增"}) => {
    return <Container>
        <Icon onClick={onClick} title={label} />
        {label}
    </Container>
};

export default AddButtonWithLabel;