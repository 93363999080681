import React from "react";
import styled from "styled-components";
import {FcDownload} from "react-icons/fc";
import useCaseNum from "../shared/useCaseNum";
import {get_token} from "../../../actions/token";
import {API_URL} from "../../../config";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.a`
  width: 7rem;
  height: 2rem;
  background-color: #FFFFFF;
  border-radius: 6px;
  outline: none;
  border: none;
  color: #6C87C6;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const usePdfUrl = () => {
    const caseNum = useCaseNum();
    const token = get_token();

    return `${API_URL}/case/${caseNum}/export?token=${token}`;
}

const CaseOnePdfDownload = () => {
    const url = usePdfUrl();

    return <Container>
        <Button target="_blank" rel="noopener noreferrer" href={url}>
            <FcDownload />下載PDF
        </Button>
    </Container>
};

export default CaseOnePdfDownload;