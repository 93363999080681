import {useMutation, useQueryClient} from "react-query";
import ajax from "../actions/ajax";
import queryKeys from "./queryKeys";
import useCaseNum from "../components/CaseOne/shared/useCaseNum";

const prefix_url = "/case/animal_detail";

export const useUpdateAnimalSpecies = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_ANIMAL_DETAIL_UPDATE,({id, value}) => {
        return ajax.put(`${prefix_url}/${id}/animal_species`, {value});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useUpdateCountMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_ANIMAL_DETAIL_UPDATE,({id, value}) => {
        return ajax.put(`${prefix_url}/${id}/count`, {value});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useUpdateComplaintCategory = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_ANIMAL_DETAIL_UPDATE,({id, values}) => {
        return ajax.put(`${prefix_url}/${id}/complaint_category`, {values});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useUpdateCurrentBrief = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_ANIMAL_DETAIL_UPDATE,({id, values}) => {
        return ajax.put(`${prefix_url}/${id}/current_brief`, {values});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useDeleteMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_ANIMAL_DETAIL_DELETE,id => {
        return ajax.delete(`${prefix_url}/${id}`);
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useAddMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_ANIMAL_DETAIL_ADD,() => {
        return ajax.post(`${prefix_url}`, {case_num});
    }, {async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};
