import _ from "lodash";
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import DropdownList from "../DropdownList";
import utils from "../../../utils";
import urgent_level_label_map from "../../../consts/urgent_level_label_map";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
});

const UrgentSelect = memo(({disabled=false, value, onChange=()=>{}})=>{
    const options = _.keys(urgent_level_label_map).map((value)=>{
        const label = utils.getUrgentLevelLabel(value);
        return {value, label};
    });

    return <DropdownList disabled={disabled} options={options} value={value} onChange={onChange} />
});

UrgentSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

export default withStyles(styles)(UrgentSelect);
