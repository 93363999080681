import _ from 'lodash';

class HTMLRenderer {
    static render(arr=[]){
        if(arr==null) return "";

        let sb = "";
        arr.filter(_.isObject).forEach(obj => {
            sb += this.renderObj(obj);
        });
        return sb;
    }

    static renderObj(node={}){
        if(node == null || !_.has(node,"type")) return "";

        //render blocks
        switch (node.type){
            case "paragraph": {
                return `<p>${this.getContent(node)}</p>`;
            }
            case "heading": {
                let level = 1;
                if(_.has(node, ["attrs", "level"]) && _.isInteger(node.attrs.level)){
                    level = node.attrs.level;
                }
                const tag = `h${level}`;
                return `<${tag}>${this.getContent(node)}</${tag}>`;
            }
            case "text": {
                return this.renderText(node);
            }
            default: {
                return "";
            }
        }
    }

    static getContent(obj){
        if(!_.has(obj,"content") || !_.isArray(obj.content)) return "";

        let sb = "";
        obj.content.forEach(child=>{
            sb += this.renderObj(child);
        })
        return sb;
    }

    static renderText(textNode){
        if(textNode==null || !_.has(textNode, "text")) return "<span />";

        let {text} = textNode;
        let marks = _.has(textNode, "marks") && _.isArray(textNode.marks)? textNode.marks : [];
        let styleStr = "";

        marks.forEach(mark => {
            let type = mark.type;
            switch(type){
                case "strong":
                    text = `<b>${text}</b>`;
                    break;
                case "em":
                    text = `<i>${text}</i>`;
                    break;
                case "u":
                    text = `<u>${text}</u>`;
                    break;
                case "del":
                    text = `<del>${text}</del>`;
                    break;
                case "link":{
                    if(_.has(mark, ["attrs", "href"]) && _.isString(mark.attrs.href)){
                        text = `<a href="${mark.attrs.href}" target="_blank">${text}</a>`;
                    }
                    break;
                }
                case "textColor":
                    if(_.has(mark, ["attrs", "color"]) && _.isString(mark.attrs.color)){
                        const color = mark.attrs.color;
                        styleStr = `color:${color};`;
                    }
                    break;
                default:
                    text = "";
            }
        });
        if(_.isEmpty(styleStr)){
            return `<span>${text}</span>`;
        }
        else{
            return `<span style="${styleStr}">${text}</span>`;
        }
    }
}

export default HTMLRenderer;
