import React from "react";
import styled from "styled-components";
import GoogleMapLink from "../../../Shared/GoogleMapLink";

const Container = styled.div`
  margin-top: 1.375rem;
`

const AddressLink = ({animal_location_city, animal_location_area, animal_location_road, animal_location_details}) => {
    return <Container>
        <GoogleMapLink address={`${animal_location_city}${animal_location_area}${animal_location_road}${animal_location_details}`} />
    </Container>
};

export default AddressLink;
