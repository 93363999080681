import _ from "lodash";
import React, {memo} from "react";
import PropTypes from "prop-types";

function get_google_map_href(address = "") {
    return `https://www.google.com.tw/maps/place/${address}`;
}

const GoogleMapLink = memo(({address = ""}) => {
    if (_.isEmpty(address)) {
        return null;
    }
    else {
        return <a
            title={address}
            rel="noopener noreferrer"
            target="_blank"
            href={get_google_map_href(address)}>
            {address}
        </a>;
    }
});

export default GoogleMapLink;

GoogleMapLink.propTypes = {
    address: PropTypes.string
};
