import React, {Fragment, useState} from "react";
import update from "immutability-helper";
import styled from "styled-components";
import PropTypes from 'prop-types';
import {useNote, useUpdate} from "../../../../queries/caseNote";
import _CaseOneNoteEditor from "../CaseOneNoteEditor";

const CaseOneNoteEditor = styled(_CaseOneNoteEditor)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const CaseOneNoteEdit = ({id, isInline=false, onCancel, onSaved}) => {
    const updateMutation = useUpdate();
    const [note, setNote] = useState({});
    const query = useNote({id}, data=>{
        setNote(data.data);
    });

    if(query.isLoading || query.isFetching){
        return <Fragment />
    }

    return <CaseOneNoteEditor
        isInline={isInline}
        note={note}
        submitText="儲存"
        disabled={updateMutation.isLoading }
        onCancel={onCancel}
        onChangeWrittenDate={value=>{
            setNote(note => update(note, {
                written_date: { $set: value}}
            ));
        }}
        onChangeTagIds={values=>{
            setNote(note => update(note, {
                tag_ids: { $set: values}}
            ));
        }}
        onChangeText={value=>{
            setNote(note => update(note, {
                text: { $set: value}}
            ));
        }}
        onSaved={()=>{
            updateMutation.mutate(note, {onSuccess(){
                onSaved();
            }});
        }}
    />
};

CaseOneNoteEdit.defaultProps = {
    onCancel: ()=>{},
    onSaved: ()=>{},
};

CaseOneNoteEdit.propTypes = {
    onCancel: PropTypes.func,
    onSaved: PropTypes.func,
};

export default CaseOneNoteEdit;
