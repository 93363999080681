import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {HiPencil} from 'react-icons/hi';

const Button = styled(HiPencil)`
  width: 25px;
  height: 25px;
  color: #6C87C6;
  
  appearance: none;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
`;

function PenButton({ className, onClick=()=>{} } ) {
    return <Button className={className} onClick={onClick} />
}

PenButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default PenButton;
