import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {MdDelete} from 'react-icons/md';

const Button = styled(MdDelete)`
  width: 25px;
  height: 25px;
  color: #F2ABAB;
  
  appearance: none;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
`;

function TrashCanButton({ className, onClick=()=>{} } ) {
    return <Button title="刪除" className={className} onClick={onClick} />
}

TrashCanButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default TrashCanButton;
