import React from "react";
import utils from "../utils/utils";
import UserList from "../components/UserList";

export const route_path = "/users";

export const title = "帳號管理";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

const UserList_Route = ()=>{
    return <UserList />
}

export default UserList_Route;

UserList_Route.propTypes = {
};
