import store from "store";

export function get_token(){
    return store.get("token");
}

export function set_token(token){
    store.set("token", token);
}

export function remove_token(){
    store.remove("token");
}