import {useEffect, useState} from "react";
import {get_token, remove_token} from "../actions/token";
import _ from "lodash";
import {redirect_route as redirect_login_route} from "../routes/Login_Route";
import axios from "axios";
import {API_URL} from "../config";
import {useDispatch} from "react-redux";
import {ON_FETCH_ME} from "../actions/types";

const findMeByToken = (token="") => {
    const headers = {token, "If-Modified-Since": "0"};
    return axios.get(`${API_URL}/me`,{ headers }).then(({data})=> {
        return data;
    });
}

const getMe = async () => {
    const token = get_token();

    if (_.isEmpty(token)) {
        redirect_login_route();
    }
    else {
        try{
            return await findMeByToken(token);
        }
        catch(err){
            remove_token();
            redirect_login_route();
        }
    }
}

const getInitLoading = (isLoginRequired=false, hasToken=false) => {
    if(!isLoginRequired){
        return false;
    }
    else if(hasToken){
        return true;
    }
    return false;
};

const useCheckLogin = (isLoginRequired=false) => {
    const dispatch = useDispatch();
    const token = get_token();
    const hasToken = !_.isEmpty(token);
    const initialLoading = getInitLoading(isLoginRequired, hasToken);
    const [isLoading, setLoading] = useState(initialLoading);

    useEffect( () => {
        const fetchMe = async () => {
            if(isLoginRequired){
                setLoading(true);
                const me = await getMe();
                dispatch({type: ON_FETCH_ME, payload: me});
                setLoading(false);
            }
        };

        fetchMe();
    }, [isLoginRequired, dispatch]);

    return {isLoading};
};


export default useCheckLogin;
