import React, {useRef} from "react";
import styled from "styled-components";
import {AiOutlineSearch} from "react-icons/ai";
import EventListener from "react-event-listener";
import keycode from "keycode";
import utils from "../../../utils";
import {route_path} from "../../../routes/CaseList_Route";
import {useLocation} from "react-router";

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 15ch;
  height: 100%;
  font: inherit;
  color: #434242;
  background-color: white;
  border-radius: 5px;
  border: 0;
  appearance: none;
  padding: 5px 5px 5px 25px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
  &:focus{
    width: 24ch;
    outline: 0;
  }
`;

const Icon = styled(AiOutlineSearch)`
  position: absolute;
  height: 100%;
  
  color: #434242;
  top: 0;
  left: 5px;
`

const getKeyword = ()=>{
    const filters = utils.get_filters();
    return utils.get_value_by_cur_filters({filters, field: "keyword"});
}

const SearchBar = () => {
    useLocation();
    const inputEl = useRef(null);
    const filters = utils.get_filters();
    const defaultValue = getKeyword();

    return <Container>
        <Icon onClick={()=>{
            inputEl.current.focus();
        }} />
        <EventListener target="window" onKeyDown={event=>{
            const isInputting = event.target===inputEl.current;

            if(isInputting && ['enter'].indexOf(keycode(event)) !== -1){
                utils.changeParams({route_path, property: "keyword", type: "String", value: event.target.value, filters});
            }
        }} />
        <Input
            type="search"
            defaultValue={defaultValue}
            ref={inputEl}
        />
    </Container>
};

export default SearchBar;
