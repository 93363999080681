const getWhistleblowerSourceLabel = value => {
    switch(value){
        case "WEB_SEARCH":
            return "網頁搜尋關鍵字";
        case "TSPCA_WEBSITE":
            return "TSPCA官網";
        case "TSPCA_TG":
            return "TSPCA IG";
        case "TSPCA_FB":
            return "TSPCA 臉書";
        case "FRIENDS":
            return "親朋好友、網友推薦";
        default :
            return "其他"
    }
};

export default getWhistleblowerSourceLabel;
