import React from "react";
import {useQueryClient} from "react-query";
import roles_map from "../../../consts/roles_map";
import RoleSelect from "../../RoleSelect";
import queryKeys from "../../../queries/queryKeys";
import utils from "../../../utils";
import {get_role_label} from "../../RoleSelect/RoleSelect";
import Switch from "../Switch";
import RaisedButton from "../RaisedButton/RaisedButton";
import ColorButton from "./ColorButton";
import {route_path} from "../../../routes/UserList_Route";
import {DEFAULT_PAGE} from "../../../config";
import {useUserActiveChange, useUserColorChange, useUserDelete, useUserRoleChange} from "../../../queries/user";
import useMe from "../../../hooks/useMe";
import UserAvatar from "../UserAvatar";
import EllipsisText from "../EllipsisText";

const useCells = ({onChangePasswordClick}) => {
    const me = useMe();
    const queryClient = useQueryClient();
    const changeColorMutation = useUserColorChange();
    const disabled_change_role = utils.disabled_change_role(me);
    const delMutation = useUserDelete();
    const changeRoleMutation = useUserRoleChange();
    const changeActiveMutation = useUserActiveChange();

    return [
        {
            align: "center",
            style: {
                minWidth: 75
            },
            component: ({display_name, color})=><UserAvatar value={display_name} color={color} />,
        },
        {
            align: "left",
            style: {
                maxWidth: 100
            },
            component: ({display_name}) => <EllipsisText>
                {display_name}
            </EllipsisText>
        },
        {
            align: "left",
            style: {
                maxWidth: 250,
            },
            component: ({email})=> <EllipsisText>{email}</EllipsisText>,
        },
        {
            align: "left",
            style: {
                minWidth: 200,
            },
            component: (record)=>{
                const {id, display_name, role} = record;
                const is_me = me.id === id;
                return disabled_change_role? <div style={{width:200}}>{roles_map[role]}</div> : <RoleSelect disabled={is_me} value={role} onChange={toRole=>{
                    changeRoleMutation.mutate({record, toRole}, {async onSuccess(){
                            await queryClient.invalidateQueries(queryKeys.USER_ALL);
                            utils.openNotification(`用戶${display_name}角色已更改為${get_role_label(toRole)}`);
                        }});
                }} />
            },
        },
        {
            align: "center",
            style: {
                width: 120,
            },
            component: (record)=>{
                const {display_name, is_active, email} = record;
                const disabled = utils.is_admin_email(email);

                return <Switch disabled={disabled} checked={is_active} onChange={is_active=>{
                    changeActiveMutation.mutate({record, is_active}, {async onSuccess(){
                        await queryClient.invalidateQueries(queryKeys.USER_ALL);
                        utils.openNotification(`用戶${display_name}已更改為${is_active ? "啟用" : "關閉"}`);
                    }});
                }} />
            },
        },
        {
            align: "center",
            style: {
                minWidth: 150,
            },
            component({id, email=""}){
                const disabled = utils.is_admin_email(email);
                return <RaisedButton disabled={disabled} color="primary" onClick={()=>onChangePasswordClick(id)}>更改密碼</RaisedButton>
            }
        },
        {
            align: "center",
            style: {
                minWidth: 160,
            },
            component({id, color, display_name=""}){
                return <ColorButton color={color} onChangeComplete={color=>{
                    changeColorMutation.mutate({id, color}, {async onSuccess(){
                            await queryClient.invalidateQueries(queryKeys.USER_ALL);
                            utils.openNotification(`用戶${display_name}顏色已更改`);
                        }});
                }} />
            }
        },
        {
            align: "center",
            style: {
                width: 150,
            },
            component(record){
                const {email="", display_name} = record;
                const is_del_disabled = utils.is_admin_email(email);

                return <RaisedButton disabled={is_del_disabled} color="secondary" onClick={()=>{
                    if(window.confirm(`確定刪除${display_name}?`)){
                        delMutation.mutate(record, {async onSuccess(){
                                await queryClient.invalidateQueries(queryKeys.USER_ALL);
                                utils.openNotification("刪除成功");
                                utils.changePage({route_path, page: DEFAULT_PAGE});
                            }});
                    }
                }}>刪除</RaisedButton>
            }
        }
    ];
};

export default useCells;
