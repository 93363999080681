import {useMutation, useQueryClient} from "react-query";
import ajax from "../actions/ajax";
import queryKeys from "./queryKeys";
import useCaseNum from "../components/CaseOne/shared/useCaseNum";

const prefix_url = "/case/file";

export const useDeleteMutation = () => {
    const caseNum = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_FILE_DELETE, id => {
        return ajax.delete(`${prefix_url}/${id}`);
    }, {
        async onSuccess() {
            await queryClient.invalidateQueries([queryKeys.CASE_ONE, caseNum]);
        }
    });
};

