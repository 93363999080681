import React from "react";
import styled, {css} from "styled-components";
import MaterialPaper from "@material-ui/core/Paper";
import useIsWeb from "../../../../hooks/useIsWeb";

const Paper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  flex-direction: ${props => props.isWeb ? "column" : "row"};
  
  ${props => props.isWeb && css`
    font-size: 1rem;
    color: #818080;
  `};
`;

const Number = styled.div`
  font-size: 1.625rem;
  font-weight: bold;
`;

const Card = ({className, label, count}) => {
    const isWeb = useIsWeb();

    return <MaterialPaper className={className} elevation={3}>
        <Paper isWeb={isWeb}>
            <div>{label}</div>
            <Number>{count.toLocaleString()}</Number>
        </Paper>
    </MaterialPaper>
};

export default Card;
