import React from 'react';
import IOSSwitch from "../../../../components/IOSSwitch";
import {useUpdateCaseAllIsPublic} from "../../../../queries/case";

const IsPublicSwitch = ({value, caseNum}) => {
    const updateCaseAllIsPublic = useUpdateCaseAllIsPublic();

    return <IOSSwitch disabled={updateCaseAllIsPublic.isLoading} checked={value} onChange={event=> {
        updateCaseAllIsPublic.mutate({case_num: caseNum, value: event.target.checked});
    }} />
}

export default IsPublicSwitch;
