import _ from "lodash";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useTagList} from "../../../../../queries/useTagList";
import CrossIcon from "../../../../Shared/File/CrossIcon";

const Chip = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
    
  background-color: #CDD7ED;
  border-radius: 15px;
  
  color: #4C659D;
  font-size: 12px;
  
  span{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 36.45px;
  }
`;

const TagChip = ({value, hasRemoved=false, onClick=()=>{}}) => {
    const {isLoading, data} = useTagList();

    if(isLoading) return <Fragment />

    const tagMap = _.mapKeys(data.data, "id");

    return <Chip>
        <span>
            {tagMap[value]?.name}
        </span>
        {hasRemoved && <CrossIcon onClick={onClick} />}
    </Chip>
};

TagChip.propTypes = {
    value: PropTypes.string,
};

export default TagChip;
