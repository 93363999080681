import React from "react";
import {useAnimalSpecies} from "../../../../queries/animalSpecies";
import DropdownList from "../../../Shared/DropdownList/DropdownList";

const AnimalSpeciesSelect = ({disabled=false, value=[], onChange}) => {
    const {isLoading, isError, data} = useAnimalSpecies();

    const options = [{value: "null", label: "尚未選擇"}].concat((data?.data || []).map(({id, name}) => {
        return {value: id, label: name};
    }));

    return <DropdownList disabled={isLoading || isError || disabled} options={options} value={value} onChange={onChange} />
};

export default AnimalSpeciesSelect;
