import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, TextField as T} from "@material-ui/core";
import useDebounceValue from "../../../hooks/useDebounceValue";

const TextField = ({className, maxLength, multiline, fullWidth, type, value: originalValue, label, onChange=()=>{}, readOnly=false}) => {
    const isComposing = React.useRef(false);
    const {value, setValue} = useDebounceValue(originalValue, onChange);

    return <FormControl className={className} readOnly={readOnly} fullWidth={fullWidth}>
        <T
            multiline={multiline}
            type={type}
            label={label}
            value={(value === 0 || value) ? value : ''}
            onCompositionStartCapture={()=>{
                isComposing.current = true;
            }}
            onCompositionUpdateCapture={()=>{
                isComposing.current = true;
            }}
            onCompositionEndCapture={()=>{
                isComposing.current = false;
                setValue(value, isComposing.current);
            }}
            onChange={e=> {
                setValue(e.target.value, isComposing.current);
            }}
            disabled={readOnly}
            inputProps={{ maxLength }}
            InputLabelProps={{
                shrink: true
            }}
        />
    </FormControl>
}

TextField.defaultProps = {
    onChange: ()=>{},
    type: "input",
    readOnly: false,
    multiline: false,
    fullWidth: false
};

TextField.propTypes = {
    onChange: PropTypes.func,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    fullWidth: PropTypes.bool
};

export default TextField;
