import React from "react";
import utils from '../utils/utils';
import Login from "../components/Login";

export const route_path = "/login";

export const redirect_login = () => {
    utils.redirect_href(route_path);
};

export const redirect_route = () => {
    utils.redirect_route(route_path);
};

function LoginRoute(){
    return <Login />;
}

export default LoginRoute;
