import {ON_NOTIFICATION_CLOSE, ON_NOTIFICATION_OPEN} from "../actions/types";

const INITIAL_STATE = {
    open: false,
    message: ""
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ON_NOTIFICATION_OPEN: {
            const {message = ""} = action.payload;
            return {...state, open: true, message};
        }
        case ON_NOTIFICATION_CLOSE: {
            return {...state, open: false, message: ''};
        }
        default: {
            return state;
        }
    }
}
