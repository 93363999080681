import React from "react";
import useIsWeb from "../../../hooks/useIsWeb";
import MarginContainer from "../../MarginContainer";
import BarWithLabel from "../shared/BarWithLabel";

const LabelAndField = ({label, value, onChange=()=>{}, component: Component}) => {
    const isWeb = useIsWeb();

    return <div>
        {!isWeb && <BarWithLabel text={label} />}
        <MarginContainer>
            <Component label={isWeb ? label: ""} value={value} onChange={onChange} fullWidth />
        </MarginContainer>
    </div>
};



export default LabelAndField;