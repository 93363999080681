import styled, {css} from "styled-components";
import {Link as RouteLink} from "react-router-dom";

const linkStyle = css`
  color: #0000EE;
  
  &:visited{
    color: #551A8B;
  }
`

const Link = styled(RouteLink)`
  ${linkStyle};
`;

export const ALink = styled.a`
  ${linkStyle};
`;

export default Link;