import {useMutation, useQueryClient} from "react-query";
import ajax from "../actions/ajax";
import queryKeys from "./queryKeys";
import useCaseNum from "../components/CaseOne/shared/useCaseNum";

const prefix_url = "/case/file_additional";

export const useDeleteMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_FILE_ADDITIONAL_DELETE,id => {
        return ajax.delete(`${prefix_url}/${id}`);
    },{async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};

export const useAddMutation = () => {
    const case_num = useCaseNum();
    const queryClient = useQueryClient();

    return useMutation(queryKeys.CASE_FILE_ADDITIONAL_ADD,async (src)=>{
        await ajax.post(prefix_url, {case_num, src});
    },{async onSuccess(){
        await queryClient.invalidateQueries([queryKeys.CASE_ONE, case_num]);
    }});
};
