import {ON_ANALYSIS_YEAR_CHANGE} from "../actions/types";
import {ANALYSIS_DEFAULT_YEAR} from "../config";

const DEFAULT_STATE = {
    year: ANALYSIS_DEFAULT_YEAR
};

const INITIAL_STATE = DEFAULT_STATE;

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ON_ANALYSIS_YEAR_CHANGE: {
            const {year=ANALYSIS_DEFAULT_YEAR} = action.payload;
            return {...state, year};
        }
        default: {
            return state;
        }
    }
}