import React, {memo} from "react";
import PropTypes from "prop-types";

const Panel = memo(({title="", children}) => {
    return <div>
        <div>
            <h1>{title}</h1>
        </div>
        <div>
            {children}
        </div>
    </div>
});

Panel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    defaultExpanded: PropTypes.bool
};

export default Panel;
