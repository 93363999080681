import React from "react";
import utils from "../../utils/utils";
import CaseListDashBoard from "./CaseListDashBoard";
import CaseListQuery from "./CaseListQuery";
import CaseList from "./CaseList";
import DefaultContainer from "../../components/DefaultContainer";

export const route_path = "/";

export const title = "案件管理";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

const CaseList_Route = ()=>{
    return <DefaultContainer>
        <CaseListDashBoard />
        <CaseListQuery />
        <CaseList />
    </DefaultContainer>
}

export default CaseList_Route;
