import React, {useState} from "react";
import update from "immutability-helper";
import {v4} from "uuid";
import PropTypes from 'prop-types';
import utils from "../../../../utils";
import useMe from "../../../../hooks/useMe";
import {useAddMutation} from "../../../../queries/caseNote";
import styled from "styled-components";
import _CaseOneNoteEditor from "../CaseOneNoteEditor";

const CaseOneNoteEditor = styled(_CaseOneNoteEditor)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const CaseOneNoteAdd = ({isInline=false, onCancel, onSaved}) => {
    const me = useMe();
    const addMutation = useAddMutation();
    const [note, setNote] = useState(
        {
            id: v4(),
            user_display_name: me.display_name,
            written_date: utils.toTaipeiTimeWithZoneIdStr(new Date()),
            text: [],
            tag_ids: []
        }
    );

    return <CaseOneNoteEditor
        isInline={isInline}
        submitText="新增"
        disabled={addMutation.isLoading}
        note={note}
        onCancel={onCancel}
        onChangeWrittenDate={value=>{
            setNote(note=>{
                return update(note, {
                    written_date: {
                        $set: value
                    }
                });
            });
        }}
        onChangeTagIds={values=>{
            setNote(note=>{
                return update(note, {
                    tag_ids: {
                        $set: values
                    }
                });
            });
        }}
        onChangeText={value=>{
            setNote(note=>{
                return update(note, {
                    text: {
                        $set: value
                    }
                });
            });
        }}
        onSaved={()=>{
            addMutation.mutate(note, {onSuccess(){
                onSaved();
            }});
        }}
    />
};

CaseOneNoteAdd.defaultProps = {
    onCancel: ()=>{},
    onSaved: ()=>{},
};

CaseOneNoteAdd.propTypes = {
    onCancel: PropTypes.func,
    onSaved: PropTypes.func,
};

export default CaseOneNoteAdd;
